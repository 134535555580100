var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-badge",
    {
      attrs: {
        bordered: "",
        overlap: "",
        "offset-x": "1.5rem",
        "offset-y": "1.5rem",
        color: "white",
        value: _vm.hasBadge,
      },
      scopedSlots: _vm._u([
        {
          key: "badge",
          fn: function () {
            return [
              _c(
                "v-icon",
                {
                  staticClass: "mb-1",
                  attrs: { size: "1rem", color: _vm.badge.color },
                },
                [_vm._v(" " + _vm._s(_vm.badge.icon) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-btn",
        {
          class: _vm.seatClass,
          style: { margin: _vm.margin, opacity: _vm.seatOpacity },
          attrs: {
            width: _vm.width,
            height: _vm.height,
            "min-width": "0",
            "min-height": "0",
            color: _vm.seat.color,
            depressed: "",
            "aria-label": _vm.seatName,
          },
          on: {
            drop: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.placeStudent.apply(null, arguments)
            },
            dragenter: function ($event) {
              $event.stopPropagation()
              return _vm.allowDropIfEnabled.apply(null, arguments)
            },
            dragover: function ($event) {
              $event.stopPropagation()
              return _vm.allowDropIfEnabled.apply(null, arguments)
            },
            click: [
              function ($event) {
                $event.stopPropagation()
                return _vm.toggleSeatSelected.apply(null, arguments)
              },
              _vm.clickSeat,
            ],
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.clickSeat.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-avatar",
            { attrs: { size: _vm.avatarSize, tile: !_vm.isCircle } },
            [
              _vm.hasStudent && _vm.hasPhoto
                ? _c(
                    "v-img",
                    {
                      staticClass: "student-in-seat",
                      attrs: {
                        src: _vm.student.photoUrl,
                        contain: "",
                        draggable: _vm.isDraggable,
                      },
                      on: { dragstart: _vm.dragStudent },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "student-tooltip",
                                            style: _vm.studentTooltipStyle,
                                            attrs: { "aria-hidden": "true" },
                                          },
                                          "span",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3420751339
                          ),
                        },
                        [_c("p", [_vm._v(_vm._s(_vm.studentLabel))])]
                      ),
                    ],
                    1
                  )
                : _vm.hasStudent
                ? _c(
                    "v-card",
                    {
                      staticClass: "student-in-seat",
                      attrs: {
                        color: _vm.classColor,
                        draggable: _vm.isDraggable,
                        tabindex: "-1",
                      },
                      on: { dragstart: _vm.dragStudent },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "student-tooltip white--text",
                                          style: _vm.studentTooltipStyle,
                                          attrs: { "aria-hidden": "true" },
                                        },
                                        "span",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("p", [_vm._v(_vm._s(_vm.studentLabel))])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }