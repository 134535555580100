var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "seatingLayoutForm" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "justify-content": "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "pb-btn",
                        {
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            fab: "",
                            small: "",
                            depressed: "",
                            label: _vm.$t("closeLabel"),
                          },
                          on: { click: _vm.closeLayout },
                        },
                        [_c("v-icon", [_vm._v("fal fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("enterSeatLayoutTitleLabel") + "*",
                          "aria-required": "true",
                          "aria-label": _vm.$t("enterSeatLayoutTitleLabel"),
                          rules: [_vm.rules.titleRequired],
                          "single-line": "",
                          autofocus: "",
                        },
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 hidden-sm-and-up",
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.toggleFlexibleMode },
                        },
                        [
                          _vm.isFlexibleMode
                            ? [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-grid"),
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("gridModeLabel"))),
                                ]),
                              ]
                            : [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-chart-network"),
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("flexibleModeLabel"))),
                                ]),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-right d-flex flex-nowrap" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 hidden-xs-only",
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.toggleFlexibleMode },
                        },
                        [
                          _vm.isFlexibleMode
                            ? [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-grid"),
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("gridModeLabel"))),
                                ]),
                              ]
                            : [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-chart-network"),
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("flexibleModeLabel"))),
                                ]),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            fab: "",
                            small: "",
                            depressed: "",
                            label: _vm.$t("saveLabel"),
                          },
                          on: { click: _vm.addLayout },
                        },
                        [_c("v-icon", [_vm._v("fal fa-check")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isFlexibleMode
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { offset: "1" } },
                        [
                          _c("v-text-field", {
                            key: _vm.localRefreshKey,
                            attrs: {
                              label: _vm.$t("enterNumRowsLabel") + "*",
                              "aria-required": "true",
                              outlined: "",
                              rules: [_vm.rules.rowsRequired],
                              type: "number",
                              min: "0",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.refreshKey.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.numRows,
                              callback: function ($$v) {
                                _vm.numRows = $$v
                              },
                              expression: "numRows",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            key: _vm.localRefreshKey,
                            attrs: {
                              label: _vm.$t("enterNumColsLabel") + "*",
                              "aria-required": "true",
                              outlined: "",
                              rules: [_vm.rules.colsRequired],
                              type: "number",
                              min: "0",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.refreshKey.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.numCols,
                              callback: function ($$v) {
                                _vm.numCols = $$v
                              },
                              expression: "numCols",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _c("v-sheet", {
                                staticClass: "legend",
                                attrs: {
                                  color: _vm.activeColor,
                                  width: "1rem",
                                  height: "1rem",
                                },
                              }),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("activeSeatsLabel"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _c("v-sheet", {
                                staticClass: "legend",
                                attrs: {
                                  color: _vm.removedColor,
                                  width: "1rem",
                                  height: "1rem",
                                },
                              }),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("removedSeatsLabel"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _vm.isFlexibleMode
                ? _c("flexible-layout", {
                    ref: "flexibleLayout",
                    attrs: {
                      "seat-settings": _vm.seatSettings,
                      "active-color": _vm.activeColor,
                      "removed-color": _vm.removedColor,
                      "initial-strokes": _vm.flexibleCanvas,
                    },
                  })
                : _c(
                    "v-container",
                    {
                      staticClass: "layout-container fill-height",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "perfect-scrollbar",
                        {
                          style: {
                            height: _vm.scrollHeight,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "90%"
                              : "",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "room-layout ma-2 pa-2",
                              attrs: { flat: "" },
                            },
                            _vm._l(_vm.seatSettings, function (row, i) {
                              return _c(
                                "v-row",
                                {
                                  key: row[0].id,
                                  staticClass: "d-flex flex-nowrap",
                                  attrs: { "no-gutters": "" },
                                },
                                _vm._l(row, function (seat, j) {
                                  return _c(
                                    "v-col",
                                    { key: seat.id },
                                    [
                                      _c("v-btn", {
                                        staticClass: "seat",
                                        attrs: {
                                          width: "5rem",
                                          height: "5rem",
                                          color: _vm.getSeatColor(seat),
                                          "aria-label": _vm.getSeatName(
                                            i,
                                            j,
                                            seat
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleSeat(seat)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }