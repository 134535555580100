
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import StudentUtils from '@/utils/student-utils';
import CommonUtils from '@/utils/common-utils';

interface Seat {
  id: number,
  name: string,
  disabled: boolean,
  color: string
}

@Component
export default class SeatAssignment extends Vue {
  @Emit()
  placeStudent(event: DragEvent) {
    return { event: event, seat: this.seat };
  }

  @Emit()
  dragStudent(event: DragEvent) {
    return { event: event, student: this.student }
  }

  @Emit()
  selectStudent() {
    return this.student;
  }

  @Emit()
  setStudent() {
    return this.seat;
  }

  @Emit()
  toggleSeatSelected() {
    return this.seat;
  }

  clickSeat() {
    if (this.hasStudent) {
      this.selectStudent();
    } else {
      this.setStudent();
    }
  }

  @Prop({ type: Object, required: true })
  seat!: Seat;

  @Prop({ type: Object, required: true })
  student!: any;

  @Prop({ type: Object, required: true })
  badge!: any;

  @Prop({ type: String, required: true })
  classColor!: string;

  @Prop({ type: String, required: true })
  margin!: string;

  @Prop({ type: String, default: '5rem' })
  width!: string;

  @Prop({ type: String, default: '5rem' })
  height!: string;

  @Prop({ type: Boolean, default: false })
  isFlexibleMode!: boolean;

  @Prop({ type: Boolean, default: false })
  isCircle!: boolean;

  @Prop({ type: Boolean, default: true })
  isDraggable!: boolean;

  get hasBadge() {
    return this.badge.color !== '' && this.badge.icon !== '';
  }

  get hasStudent() {
    return this.student.studentId !== undefined;
  }

  get hasPhoto() {
    return CommonUtils.hasText(this.student.photoUrl);
  }

  get initials() {
    return StudentUtils.getStudentInitials(this.student);
  }

  get studentLabel() {
    return StudentUtils.getStudentLabel(this.student);
  }

  get studentTooltipStyle() {
    return {
      width: this.avatarSize,
      height: this.avatarSize,
      'line-height': this.avatarSize
    }
  }

  get avatarSize() {
    return 'max(calc(' + this.width + ' - 0.5rem), calc(' + this.height + ' - 0.5rem))';
  }

  get seatOpacity() {
    if (this.isFlexibleMode && !this.hasStudent) {
      return 0.2;
    } else {
      return 1;
    }
  }

  get seatClass() {
    return this.isCircle ? 'seat rounded-circle' : 'seat';
  }

  get seatName() {
    if (this.seat.disabled) {
      return this.seat.name + this.$t('disabledLabel');
    } else if (this.student) {
      return this.seat.name + this.studentLabel;
    } else {
      return this.seat.name + this.$t('disabledLabel');
    }
  }

  allowDropIfEnabled(event: DragEvent) {
    if (!this.seat.disabled) {
      event.preventDefault();
    }
  }
}
