var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "seatingCharts",
      attrs: { color: "#ffffff", "min-height": "100vh" }
    },
    [
      !_vm.isEditingLayout && !_vm.isEditingSeating
        ? _c("div", [
            _vm.isViewingLayouts
              ? _c(
                  "div",
                  { class: { "pt-5": _vm.$vuetify.breakpoint.mdAndUp } },
                  [
                    _c("v-data-table", {
                      ref: "table",
                      staticClass: "data-table",
                      attrs: {
                        headers: _vm.layoutHeaders,
                        items: _vm.layouts,
                        height: _vm.tableHeight,
                        "fixed-header": "",
                        search: _vm.layoutSearch,
                        "show-select": _vm.layoutTableEdit,
                        "hide-default-footer": "",
                        "disable-pagination": "",
                        loading: _vm.isLoading,
                        "loading-text": _vm.$t("listLoadingMsg")
                      },
                      on: { "click:row": _vm.editLayout },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function(ref) {
                              var items = ref.items
                              var isSelected = ref.isSelected
                              var select = ref.select
                              return [
                                _c(
                                  "tbody",
                                  {
                                    ref: "listContainer",
                                    staticClass: "list-container"
                                  },
                                  _vm._l(items, function(item, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        class: _vm.mobileTableClass,
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { tabindex: "0", role: "row" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.editLayout(item)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.editLayout(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm.layoutTableEdit
                                          ? _c(
                                              "td",
                                              {
                                                class: _vm.mobileRowClass,
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    select(
                                                      item,
                                                      !isSelected(item)
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.isMobileMode
                                                  ? _c("div", {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    })
                                                  : _vm._e(),
                                                _c("v-checkbox", {
                                                  class: _vm.mobileCellClass,
                                                  staticStyle: {
                                                    margin: "0px",
                                                    padding: "0px"
                                                  },
                                                  attrs: {
                                                    "input-value": isSelected(
                                                      item
                                                    ),
                                                    color: !_vm.$vuetify.theme
                                                      .dark
                                                      ? "#7a7a7a"
                                                      : "#ffffff",
                                                    "hide-details": "",
                                                    "aria-label": item.name
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        item,
                                                        !isSelected(item)
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.layoutHeaders[0]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.settings.flexible
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    staticClass:
                                                      "indigo--text text-lighten-4",
                                                    class: _vm.mobileCellClass,
                                                    style: {
                                                      cursor: _vm.pointer,
                                                      "font-weight":
                                                        _vm.chipFontWeight
                                                    },
                                                    attrs: {
                                                      label: "",
                                                      color: "#e8ebf6"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.type))]
                                                )
                                              : _c(
                                                  "v-chip",
                                                  {
                                                    class:
                                                      _vm.mobileCellClass +
                                                      _vm.highContrastMode
                                                        ? " primary--text"
                                                        : "blue--text text-lighten-2",
                                                    style: {
                                                      cursor: _vm.pointer,
                                                      "font-weight":
                                                        _vm.chipFontWeight
                                                    },
                                                    attrs: {
                                                      label: "",
                                                      color: "#e5f3fe"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(item.type))]
                                                )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.layoutHeaders[1]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.dimensions))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.layoutHeaders[2]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.editLayoutTitle
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        autofocus: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        "append-icon":
                                                          "fa fa-check",
                                                        value: item.name,
                                                        disabled: _vm.isLoading
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateLayoutTitle(
                                                            item,
                                                            $event
                                                          )
                                                        },
                                                        blur:
                                                          _vm.disableEditable,
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.saveLayoutTitle(
                                                            item
                                                          )
                                                        },
                                                        "click:append": function(
                                                          $event
                                                        ) {
                                                          return _vm.saveLayoutTitle(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass,
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.toggleLayoutTitleEditable(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name))
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 hover-edit-icon"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-pencil"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-3 hover-button-primary",
                                                    attrs: {
                                                      small: "",
                                                      text: "",
                                                      color:
                                                        _vm.$vuetify.breakpoint
                                                          .name === "xs"
                                                          ? "primary"
                                                          : "transparent"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.editLayout(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("editLabel")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-3 hover-button-error",
                                                    attrs: {
                                                      small: "",
                                                      text: "",
                                                      color:
                                                        _vm.$vuetify.breakpoint
                                                          .name === "xs"
                                                          ? "error"
                                                          : "transparent"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.confirmDeleteLayout(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("deleteLabel")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        386968723
                      ),
                      model: {
                        value: _vm.selectedLayouts,
                        callback: function($$v) {
                          _vm.selectedLayouts = $$v
                        },
                        expression: "selectedLayouts"
                      }
                    }),
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          width: "unset",
                          persistent: "",
                          "retain-focus": false
                        },
                        model: {
                          value: _vm.showDeleteConfirmation,
                          callback: function($$v) {
                            _vm.showDeleteConfirmation = $$v
                          },
                          expression: "showDeleteConfirmation"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          { staticStyle: { overflow: "hidden" } },
                          [
                            _c(
                              "v-card-title",
                              { attrs: { justify: "center" } },
                              [_vm._v(_vm._s(_vm.$t("seatLayoutLabel")))]
                            ),
                            _c("v-card-text", [
                              _vm._v(_vm._s(_vm.$t("confirmDeleteSeatLayout")))
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click: _vm.cancelDeleteLayout
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("cancelLabel")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              color: "error"
                                            },
                                            on: { click: _vm.deleteLayout }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("continueLabel")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          width: "unset",
                          persistent: "",
                          "retain-focus": false
                        },
                        model: {
                          value: _vm.showDeleteMultipleConfirmation,
                          callback: function($$v) {
                            _vm.showDeleteMultipleConfirmation = $$v
                          },
                          expression: "showDeleteMultipleConfirmation"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          { staticStyle: { overflow: "hidden" } },
                          [
                            _c(
                              "v-card-title",
                              { attrs: { justify: "center" } },
                              [_vm._v(_vm._s(_vm.$t("seatLayoutLabel")))]
                            ),
                            _c("v-card-text", [
                              _vm._v(_vm._s(_vm.$t("confirmDeleteSeatLayouts")))
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click: _vm.cancelDeleteLayouts
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("cancelLabel")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: _vm.deleteSelectedLayouts
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("continueLabel")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { class: { "pt-5": _vm.$vuetify.breakpoint.mdAndUp } },
                  [
                    _c("v-data-table", {
                      ref: "table",
                      staticClass: "data-table",
                      attrs: {
                        headers: _vm.seatingHeaders,
                        items: _vm.filteredSeating,
                        height: _vm.tableHeight,
                        "fixed-header": "",
                        search: _vm.seatingSearch,
                        "show-select": _vm.seatingTableEdit,
                        loading: _vm.isLoading,
                        "loading-text": _vm.$t("listLoadingMsg"),
                        "hide-default-footer": "",
                        "disable-pagination": ""
                      },
                      on: { "click:row": _vm.editSeating },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function(ref) {
                              var items = ref.items
                              var isSelected = ref.isSelected
                              var select = ref.select
                              return [
                                _c(
                                  "tbody",
                                  {
                                    ref: "listContainer",
                                    staticClass: "list-container"
                                  },
                                  _vm._l(items, function(item, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        class: _vm.mobileTableClass,
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { tabindex: "0", role: "row" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.editSeating(item)
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.editSeating(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm.seatingTableEdit
                                          ? _c(
                                              "td",
                                              {
                                                class: _vm.mobileRowClass,
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    select(
                                                      item,
                                                      !isSelected(item)
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.isMobileMode
                                                  ? _c("div", {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    })
                                                  : _vm._e(),
                                                _c("v-checkbox", {
                                                  class: _vm.mobileCellClass,
                                                  staticStyle: {
                                                    margin: "0px",
                                                    padding: "0px"
                                                  },
                                                  attrs: {
                                                    "input-value": isSelected(
                                                      item
                                                    ),
                                                    color: !_vm.$vuetify.theme
                                                      .dark
                                                      ? "#7a7a7a"
                                                      : "#ffffff",
                                                    "hide-details": "",
                                                    "aria-label": item.name
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        item,
                                                        !isSelected(item)
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seatingHeaders[0]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.editClassSeatingTitle
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        autofocus: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        "append-icon":
                                                          "fa fa-check",
                                                        value: item.name,
                                                        disabled: _vm.isLoading
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateClassSeatingTitle(
                                                            item,
                                                            $event
                                                          )
                                                        },
                                                        blur:
                                                          _vm.disableEditable,
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.saveClassSeatingTitle(
                                                            item
                                                          )
                                                        },
                                                        "click:append": function(
                                                          $event
                                                        ) {
                                                          return _vm.saveClassSeatingTitle(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass,
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.toggleClassSeatingTitleEditable(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name))
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 hover-edit-icon"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-pencil"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seatingHeaders[1]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.layoutName))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seatingHeaders[2]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.className))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seatingHeaders[3]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.startToShow))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seatingHeaders[4]
                                                          .text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [_vm._v(_vm._s(item.endToShow))]
                                            )
                                          ]
                                        ),
                                        _c("td", { class: _vm.mobileRowClass }),
                                        _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-3 hover-button-primary",
                                                    attrs: {
                                                      small: "",
                                                      text: "",
                                                      color:
                                                        _vm.$vuetify.breakpoint
                                                          .name === "xs"
                                                          ? "primary"
                                                          : "transparent"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.editSeating(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("editLabel")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-3 hover-button-error",
                                                    attrs: {
                                                      small: "",
                                                      text: "",
                                                      color:
                                                        _vm.$vuetify.breakpoint
                                                          .name === "xs"
                                                          ? "error"
                                                          : "transparent"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.confirmDeleteSeating(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("deleteLabel")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        246103070
                      ),
                      model: {
                        value: _vm.selectedSeating,
                        callback: function($$v) {
                          _vm.selectedSeating = $$v
                        },
                        expression: "selectedSeating"
                      }
                    }),
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          width: "unset",
                          persistent: "",
                          "retain-focus": false
                        },
                        model: {
                          value: _vm.showDeleteConfirmation,
                          callback: function($$v) {
                            _vm.showDeleteConfirmation = $$v
                          },
                          expression: "showDeleteConfirmation"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          { staticStyle: { overflow: "hidden" } },
                          [
                            _c(
                              "v-card-title",
                              { attrs: { justify: "center" } },
                              [_vm._v(_vm._s(_vm.$t("seatAssignmentLabel")))]
                            ),
                            _c("v-card-text", [
                              _vm._v(
                                _vm._s(_vm.$t("confirmDeleteSeatAssignment"))
                              )
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click: _vm.cancelDeleteSeating
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("cancelLabel")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              color: "error"
                                            },
                                            on: { click: _vm.deleteSeating }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("continueLabel")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          width: "unset",
                          persistent: "",
                          "retain-focus": false
                        },
                        model: {
                          value: _vm.showDeleteMultipleConfirmation,
                          callback: function($$v) {
                            _vm.showDeleteMultipleConfirmation = $$v
                          },
                          expression: "showDeleteMultipleConfirmation"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          { staticStyle: { overflow: "hidden" } },
                          [
                            _c(
                              "v-card-title",
                              { attrs: { justify: "center" } },
                              [_vm._v(_vm._s(_vm.$t("seatAssignmentLabel")))]
                            ),
                            _c("v-card-text", [
                              _vm._v(
                                _vm._s(_vm.$t("confirmDeleteSeatAssignments"))
                              )
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click:
                                                _vm.cancelDeleteMultipleSeating
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("cancelLabel")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: _vm.deleteMultipleSeating
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("continueLabel")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "pb-base-modal",
                      {
                        attrs: {
                          maxWidth: 500,
                          expandable: false,
                          withActions: false
                        },
                        on: { apply: _vm.applyFilter },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.$t("filterLabel")))]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3598852824
                        ),
                        model: {
                          value: _vm.filterOptions.active,
                          callback: function($$v) {
                            _vm.$set(_vm.filterOptions, "active", $$v)
                          },
                          expression: "filterOptions.active"
                        }
                      },
                      [
                        _c(
                          "v-container",
                          { staticClass: "pb-7" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-select", {
                                      staticClass: "mt-1",
                                      attrs: {
                                        label: _vm.$t("classLabel"),
                                        "width:64px": "",
                                        "display:inline-flex": "",
                                        "return-object": "",
                                        "hide-details": "auto",
                                        items: _vm.filterOptions.classList,
                                        "item-text": "text",
                                        outlined: "",
                                        dense: ""
                                      },
                                      model: {
                                        value:
                                          _vm.filterSelections.selectedClass,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterSelections,
                                            "selectedClass",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterSelections.selectedClass"
                                      }
                                    }),
                                    _c("v-select", {
                                      staticClass: "mt-5",
                                      attrs: {
                                        label: _vm.$t("layoutLabel"),
                                        "width:64px": "",
                                        "display:inline-flex": "",
                                        "return-object": "",
                                        "hide-details": "auto",
                                        items: _vm.filterOptions.layoutList,
                                        "item-text": "name",
                                        outlined: "",
                                        dense: ""
                                      },
                                      model: {
                                        value:
                                          _vm.filterSelections.selectedLayout,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterSelections,
                                            "selectedLayout",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterSelections.selectedLayout"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm.isEditingLayout
        ? _c(
            "div",
            [
              _c("edit-layout", {
                attrs: { "original-layout": _vm.layoutToEdit },
                on: {
                  "close-layout": _vm.viewLayouts,
                  "save-layout": _vm.refreshAndViewLayouts
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("edit-seating", {
                attrs: { originalSeating: _vm.seatingToEdit },
                on: {
                  "close-seating": _vm.viewSeating,
                  "save-seating": _vm.refreshAndViewSeating
                }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }