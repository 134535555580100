
import { Component, Vue, Watch } from 'vue-property-decorator';
import EditLayout from '@/components/seatingcharts/EditLayout.vue';
import EditSeating from '@/components/seatingcharts/EditSeating.vue';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import { FormError } from '@/errors';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import { tableWidths } from '@/constants/index';
import DateTimeUtils from '@/utils/date-time-utils';

const seatingcharts = namespace('seatingcharts');
const classes = namespace('classes');
const settings = namespace('settings');

enum Mode {
  ViewLayouts,
  ViewSeating,
  EditLayout,
  EditSeating
}

interface Seat {
  id: number,
  disabled: boolean
}

interface Layout {
  cols: number,
  name: string,
  rows: number,
  id?: number,
  settings: {
    flexible?: boolean,
    seatSettings: Seat[],
    flexibleCanvas: any[]
  }
}

@Component({
  mixins: [PageLifeCycleMixin, TableResizeMixin],
  components: { EditLayout, EditSeating }
})
export default class SeatingCharts extends Vue {
  tableHeight!: any;
  currentMode = Mode.ViewLayouts;
  layoutToEdit: any = null;
  seatingToEdit: any = null;
  layoutToDelete: any = null;
  seatingToDelete: any = null;
  isLoading = true;
  showDeleteConfirmation = false;
  showDeleteMultipleConfirmation = false;
  layoutSearch = '';
  seatingSearch = '';
  layoutTableEdit = false;
  seatingTableEdit = false;
  selectedLayouts: any[] = [];
  selectedSeating: any[] = [];
  filteredSeating: any[] = [];
  localLayouts:any[] = [];
  layoutHeaders = [
    { text: this.$t('typeLabel'), value: 'type', width: this.tableWidths.shortText },
    { text: this.$t('dimensionsLabel'), value: 'dimensions', width: this.tableWidths.mediumText },
    { text: this.$t('titleLabel'), value: 'name' },
    { text: '', value: 'controls', sortable: false, width: this.tableWidths.action2 }
  ];

  seatingHeaders = [
    { text: this.$t('titleLabel'), value: 'name', width: this.tableWidths.longText },
    { text: this.$t('layoutLabel'), value: 'layoutName', width: this.tableWidths.longText },
    { text: this.$t('classLabel'), value: 'className', width: this.tableWidths.shortText },
    { text: this.$t('startLabel'), value: 'startToShow', width: this.tableWidths.fullDate },
    { text: this.$t('endLabel'), value: 'endToShow', width: this.tableWidths.fullDate },
    { value: 'spacer', sortable: false },
    { text: '', value: 'controls', sortable: false, width: this.tableWidths.action2 }
  ];

  filterSelections = {
    selectedClass: { text: this.$t('allClassesLabel'), value: 0 },
    selectedLayout: { name: this.$t('allLayoutsLabel'), id: 0 }
  }

  filterOptions = {
    isFiltered: false,
    active: false,
    classList: [this.filterSelections.selectedClass],
    layoutList: [this.filterSelections.selectedLayout]
  }

  @seatingcharts.Action
  loadRoomLayouts!: () => Promise<any>;

  @seatingcharts.Action
  deleteRoomLayout!: (params?: any) => Promise<any>;

  @seatingcharts.Action
  loadSeatingAssignments!: () => Promise<any>;

  @seatingcharts.Action
  deleteSeatingAssignment!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: () => Promise<any>;

  @classes.Getter
  getClassItems!: any[];

  @seatingcharts.Getter
  getRoomLayouts!: any[];

  @seatingcharts.Getter
  getSeatingAssignments!: any[];

  @seatingcharts.Action
  addRoomLayout!: (params?: any) => Promise<any>;

  @seatingcharts.Action
  addSeatingAssignment!: (params?: any) => Promise<any>;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @Watch('currentMode')
  onModeChanged() {
    this.layoutTableEdit = false;
    this.seatingTableEdit = false;
  }

  @Watch('isLoading')
  disableEditable() {
    if (!this.isLoading) {
      const layouts = ld.cloneDeep(this.layouts);
      layouts.forEach(t => {
        t.editLayoutTitle = false;
      });
      const seatings = ld.cloneDeep(this.filteredSeating);
      seatings.forEach((l:any) => {
        l.editClassSeatingTitle = false;
      });
      this.layouts = layouts;
      this.filteredSeating = seatings;
    }
  }

  get chipFontWeight() {
    if (this.highContrastMode) {
      return 550;
    } else {
      return 500;
    }
  }

  get tableWidths() {
    return tableWidths;
  }

  get layouts() {
    return this.localLayouts;
  }

  set layouts(v:any[]) {
    this.localLayouts = v;
  }

  get defaultLayouts() {
    return ld.cloneDeep(this.getRoomLayouts).map(l => {
      if (l.settings.flexible) {
        l.type = 'Flexible';
        l.dimensions = 'N/A';
      } else {
        l.type = 'Grid';
        l.dimensions = `${l.rows} x ${l.cols}`;
      }
      return l;
    });
  }

  get seating() {
    return this.getSeatingAssignments.map((item) => {
      if (!item.className) {
        item.className = this.$t('allDayLabel');
      }

      return item;
    });
  }

  get classes() {
    return this.getClassItems;
  }

  get editorOpen() {
    return this.isEditingLayout || this.isEditingSeating;
  }

  get isViewingLayouts() {
    return this.currentMode === Mode.ViewLayouts;
  }

  get isEditingLayout() {
    return this.currentMode === Mode.EditLayout;
  }

  get isEditingSeating() {
    return this.currentMode === Mode.EditSeating;
  }

  get isSelectedLayoutsEmpty() {
    return this.selectedLayouts.length === 0;
  }

  get isSelectedSeatingEmpty() {
    return this.selectedSeating.length === 0;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get isMobileMode() {
    return this.$vuetify.breakpoint.xs;
  }

  get mobileTableClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-table-row' : '';
  }

  get mobileRowClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row' : '';
  }

  get mobileHeaderClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row__header' : '';
  }

  get mobileCellClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row__cell' : '';
  }

  @Watch('localPage')
  onPageChange() {
    if (this.localPage === 'room-layouts') {
      this.currentMode = Mode.ViewLayouts;
      this.refreshAndViewLayouts();
    } else {
      this.currentMode = Mode.ViewSeating;
      this.refreshAndViewSeating();
    }
  }

  applyFilter() {
    this.filteredSeating = this.seating.filter((item) => {
      return this.filterItemByClass(item) && this.filterItemByLayout(item);
    });
    this.filterOptions.active = false;
    this.filterOptions.isFiltered = true;
  }

  clearFilter() {
    this.filteredSeating = this.seating;
    this.filterOptions.isFiltered = false;
    this.filterSelections.selectedClass = this.filterOptions.classList[0];
    this.filterSelections.selectedLayout = this.filterOptions.layoutList[0];
    this.seatingSearch = '';
  }

  filterItemByClass(item: any) {
    return this.filterSelections.selectedClass.value === 0 ||
      item.subjectId === this.filterSelections.selectedClass.value;
  }

  filterItemByLayout(item: any) {
    return this.filterSelections.selectedLayout.id === 0 ||
      item.layoutId === this.filterSelections.selectedLayout.id;
  }

  toggleLayoutTableEdit() {
    this.layoutTableEdit = !this.layoutTableEdit;
    this.selectedLayouts = [];
  }

  toggleSeatingTableEdit() {
    this.seatingTableEdit = !this.seatingTableEdit;
    this.selectedSeating = [];
  }

  addLayout() {
    this.layoutToEdit = null;
    this.currentMode = Mode.EditLayout;
  }

  editLayout(layout: any) {
    this.layoutToEdit = JSON.parse(JSON.stringify(layout));
    this.currentMode = Mode.EditLayout;
  }

  confirmDeleteLayout(layout: any) {
    this.layoutToDelete = layout;
    this.showDeleteConfirmation = true;
  }

  deleteLayout() {
    this.showDeleteConfirmation = false;

    this.isLoading = true;

    this.deleteRoomLayout(this.layoutToDelete).then(() => {
      this.refreshLayouts(false);
    });
  }

  cancelDeleteLayout() {
    this.layoutToDelete = null;
    this.showDeleteConfirmation = false;
  }

  confirmDeleteLayouts() {
    this.showDeleteMultipleConfirmation = true;
  }

  deleteSelectedLayouts() {
    this.showDeleteMultipleConfirmation = false;

    this.isLoading = true;

    const promises = this.selectedLayouts.map((layout) => {
      return this.deleteRoomLayout(layout);
    });

    Promise.allSettled(promises).then(() => {
      this.selectedLayouts = [];
      this.refreshLayouts(false);
    });
  }

  cancelDeleteLayouts() {
    this.showDeleteMultipleConfirmation = false;
  }

  addSeating() {
    this.seatingToEdit = null;
    this.currentMode = Mode.EditSeating;
  }

  editSeating(seating: any) {
    this.seatingToEdit = JSON.parse(JSON.stringify(seating));
    this.currentMode = Mode.EditSeating;
  }

  confirmDeleteSeating(seating: any) {
    this.seatingToDelete = seating;
    this.showDeleteConfirmation = true;
  }

  deleteSeating() {
    this.showDeleteConfirmation = false;

    this.isLoading = true;
    this.deleteSeatingAssignment(this.seatingToDelete).then(() => {
      this.refreshSeating();
      this.seatingToDelete = null;
    });
  }

  cancelDeleteSeating() {
    this.seatingToDelete = null;
    this.showDeleteConfirmation = false;
  }

  confirmDeleteMultipleSeating() {
    this.showDeleteMultipleConfirmation = true;
  }

  deleteMultipleSeating() {
    this.showDeleteMultipleConfirmation = false;

    this.isLoading = true;

    const promises = this.selectedSeating.map((seating) => {
      this.selectedSeating = [];
      return this.deleteSeatingAssignment(seating);
    });

    Promise.allSettled(promises).then(() => {
      this.refreshSeating();
    });
  }

  cancelDeleteMultipleSeating() {
    this.showDeleteMultipleConfirmation = false;
  }

  viewLayouts() {
    this.currentMode = Mode.ViewLayouts;
  }

  refreshAndViewLayouts() {
    this.refreshLayouts(false);
    this.currentMode = Mode.ViewLayouts;
  }

  viewSeating() {
    this.currentMode = Mode.ViewSeating;
  }

  refreshAndViewSeating() {
    this.refreshSeating();
    this.refreshClasses();
    this.currentMode = Mode.ViewSeating;
  }

  async refreshLayouts(initialLoad:boolean) {
    if (initialLoad) CommonUtils.showLoading();
    this.isLoading = true;
    return this.loadRoomLayouts().then(() => {
      this.layouts = this.defaultLayouts;
      this.filterOptions.layoutList.push(...this.layouts);
      this.isLoading = false;
    }).finally(CommonUtils.hideLoading);
  }

  async refreshSeating(initialLoad?:boolean) {
    if (initialLoad) CommonUtils.showLoading();
    this.isLoading = true;
    return this.loadSeatingAssignments().then(() => {
      this.filteredSeating = this.seating;
      this.isLoading = false;
    }).finally(CommonUtils.hideLoading);
  }

  async refreshClasses() {
    this.isLoading = true;

    return this.loadClasses().then(() => {
      this.filterOptions.classList.push(...this.classes);

      this.isLoading = false;
    });
  }

  initialize() {
    this.refreshLayouts(true).then(async () => {
      if (this.localPage !== 'room-layouts') {
        return this.refreshSeating(true).then(() => {
          return this.refreshClasses();
        });
      } else {
        return Promise.resolve();
      }
    })
  }

  created() {
    this.initialize();
    const query = this.$route.query || {};
    if (Object.keys(query).length > 0) {
      if (+query.currentMode === Mode.EditLayout) {
        this.addLayout();
      } else if (+query.currentMode === Mode.EditSeating) {
        this.addSeating();
      }
      this.$router.replace({ query: {} });
    } else if (this.localPage === 'room-layouts') {
      this.currentMode = Mode.ViewLayouts;
    } else {
      this.currentMode = Mode.ViewSeating;
    }
  }

  toggleLayoutTitleEditable(item: any) {
    const layouts = ld.cloneDeep(this.layouts);
    layouts.forEach((l:any) => {
      l.editLayoutTitle = false;
    });
    const layout = layouts.find((l:any) => l.id === item.id);
    layout.editLayoutTitle = !layout.editLayoutTitle;
    this.layouts = layouts;
  }

  updateLayoutTitle(item: any, value: string) {
    const layouts = ld.cloneDeep(this.layouts);
    const layout = layouts.find((l:any) => l.id === item.id);
    layout.name = value;
    this.layouts = layouts;
  }

  saveLayoutTitle(item: any) {
    const layouts = ld.cloneDeep(this.layouts);
    const layout:Layout = layouts.find(c => c.id === item.id);
    const origLayout = this.defaultLayouts.find(c => c.id === item.id);
    this.isLoading = true;
    if (item.name !== origLayout.name) {
      this.addRoomLayout(layout)
        .then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg71') as string);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          }
          this.isLoading = false;
        });
    } else {
      this.$nextTick(() => {
        this.isLoading = false;
      })
    }
  }

  toggleClassSeatingTitleEditable(item: any) {
    const seatings = ld.cloneDeep(this.filteredSeating);
    seatings.forEach((l:any) => {
      l.editClassSeatingTitle = false;
    });
    const layout = seatings.find((l:any) => l.id === item.id);
    layout.editClassSeatingTitle = !layout.editClassSeatingTitle;
    this.filteredSeating = seatings;
  }

  updateClassSeatingTitle(item: any, value: string) {
    const seatings = ld.cloneDeep(this.filteredSeating);
    const seating = seatings.find((l:any) => l.id === item.id);
    seating.name = value;
    this.filteredSeating = seatings;
  }

  saveClassSeatingTitle(item: any) {
    const seatings = ld.cloneDeep(this.filteredSeating);
    const seating:Layout = seatings.find(c => c.id === item.id);
    const origSeating = this.seating.find(c => c.id === item.id);
    this.isLoading = true;
    if (item.name !== origSeating.name) {
      this.addSeatingAssignment(seating)
        .then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg72') as string);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          }
          this.isLoading = false;
        });
    } else {
      this.$nextTick(() => {
        this.isLoading = false;
      })
    }
  }
}
