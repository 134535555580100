var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isAddingLayout
    ? _c(
        "div",
        {
          on: {
            drop: function ($event) {
              $event.preventDefault()
              return _vm.placeStudent.apply(null, arguments)
            },
            dragenter: function ($event) {
              $event.preventDefault()
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
            click: _vm.clearSeatSelection,
            mouseenter: _vm.disableResizeable,
            mouseleave: _vm.enableResizeable,
          },
        },
        [
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-form",
                { ref: "seatingChartForm" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { "justify-content": "center" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            depressed: "",
                                            label: _vm.$t("closeLabel"),
                                          },
                                          on: { click: _vm.closeSeating },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-xmark"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.$t("enterSeatingTitleLabel") +
                                            "*",
                                          "aria-required": "true",
                                          "aria-label": _vm.$t(
                                            "enterSeatingTitleLabel"
                                          ),
                                          rules: [_vm.rules.titleRequired],
                                          "single-line": "",
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.title,
                                          callback: function ($$v) {
                                            _vm.title = $$v
                                          },
                                          expression: "title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            depressed: "",
                                            label: _vm.$t("saveLabel"),
                                          },
                                          on: { click: _vm.addSeating },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-check"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.isColoring
                            ? _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "justify-content": "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label:
                                                _vm.$t("layoutLabel") + "*",
                                              "aria-required": "true",
                                              items: _vm.layouts,
                                              "item-text": "name",
                                              rules: [_vm.rules.layoutRequired],
                                              "return-object": "",
                                              outlined: "",
                                              "hide-details": "auto",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.id == 0
                                                        ? _c(
                                                            "v-list-item",
                                                            _vm._b(
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      _vm.isAddingLayout = true
                                                                    },
                                                                },
                                                              },
                                                              "v-list-item",
                                                              data.attrs,
                                                              false
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-plus"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.item.name
                                                                ) + " "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "v-list-item",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "v-list-item",
                                                                data.attrs,
                                                                false
                                                              ),
                                                              data.on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.item.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              612096283
                                            ),
                                            model: {
                                              value: _vm.localSelectedLayout,
                                              callback: function ($$v) {
                                                _vm.localSelectedLayout = $$v
                                              },
                                              expression: "localSelectedLayout",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: _vm.$t("periodLabel"),
                                              items: _vm.classItems,
                                              "item-text": "text",
                                              "return-object": "",
                                              outlined: "",
                                              "hide-details": "auto",
                                            },
                                            on: { change: _vm.refreshStudents },
                                            model: {
                                              value: _vm.selectedClass,
                                              callback: function ($$v) {
                                                _vm.selectedClass = $$v
                                              },
                                              expression: "selectedClass",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("pb-date-time-range-field", {
                                            attrs: {
                                              hideDetails: "auto",
                                              start: _vm.dateRange[0],
                                              end: _vm.dateRange[1],
                                            },
                                            on: {
                                              "update:start": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.dateRange,
                                                  0,
                                                  $event
                                                )
                                              },
                                              "update:end": function ($event) {
                                                return _vm.$set(
                                                  _vm.dateRange,
                                                  1,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isLoading
                            ? _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "justify-content": "center" } },
                                    [
                                      _c(
                                        "perfect-scrollbar",
                                        {
                                          staticClass: "ml-3",
                                          staticStyle: {
                                            height: "calc(100vh - 300px)",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "student-list",
                                              style: {
                                                "max-width":
                                                  _vm.studentListMaxWidth,
                                              },
                                              attrs: { role: "list" },
                                            },
                                            _vm._l(
                                              _vm.sortedStudentsMap,
                                              function ([id, student]) {
                                                return _c(
                                                  "v-row",
                                                  { key: id },
                                                  [
                                                    !_vm.isAssignedSeat(student)
                                                      ? _c(
                                                          "v-card",
                                                          {
                                                            class:
                                                              _vm.studentCardClass,
                                                            attrs: {
                                                              outlined: "",
                                                              draggable: "true",
                                                              tabindex: "0",
                                                              role: "listitem",
                                                            },
                                                            on: {
                                                              dragstart:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.dragStudent(
                                                                    $event,
                                                                    student
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectStudent(
                                                                            student
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm.studentListClass,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm.classColor,
                                                                                size: "2.5rem",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm.hasPhoto(
                                                                              student
                                                                            )
                                                                              ? _c(
                                                                                  "v-img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: student.photoUrl,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "white--text",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.getInitials(
                                                                                          student
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2 mt-3",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.getStudentLabel(
                                                                                  student
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm.miniStudentListClass,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                right:
                                                                                  "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "activator",
                                                                                    fn: function ({
                                                                                      on,
                                                                                      attrs,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "v-avatar",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      _vm.classColor,
                                                                                                    size: "2.5rem",
                                                                                                  },
                                                                                              },
                                                                                              "v-avatar",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm.hasPhoto(
                                                                                              student
                                                                                            )
                                                                                              ? _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        src: student.photoUrl,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "white--text",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.getInitials(
                                                                                                          student
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.getStudentLabel(
                                                                                      student
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                justify: "center",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "perfect-scrollbar",
                                                {
                                                  style: {
                                                    "max-width":
                                                      _vm.seatingControlsMaxWidth,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-nowrap",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click:
                                                              _vm.shuffleSeating,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-shuffle"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "shuffleLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click:
                                                              _vm.shuffleSeatingByGender,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-shuffle"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "shuffleByGenderLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click:
                                                              _vm.resetSeating,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-right-from-bracket fa-flip-horizontal"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "clearAllLabel",
                                                                    { text: "" }
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.isColoring &&
                                                      !_vm.isFlexibleMode
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.endColoring,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-xmark"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "doneLabel"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : !_vm.isFlexibleMode
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.startColoring,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-brush"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "colorSeatsLabel"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.isLayoutSelected
                                            ? _c(
                                                "v-row",
                                                {
                                                  attrs: { justify: "center" },
                                                },
                                                [
                                                  _vm.isFlexibleMode
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "mt-8" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "canvas-container",
                                                              style:
                                                                _vm.canvasContainerStyle,
                                                            },
                                                            [
                                                              _c(
                                                                "perfect-scrollbar",
                                                                {
                                                                  ref: "canvas-scroll",
                                                                  style:
                                                                    _vm.canvasScrollStyle,
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    _vm.layouts,
                                                                    function (
                                                                      layout
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: layout.id,
                                                                        },
                                                                        [
                                                                          layout.id ===
                                                                          _vm
                                                                            .selectedLayout
                                                                            .id
                                                                            ? _c(
                                                                                "vue-drawing-canvas",
                                                                                {
                                                                                  ref: "canvas",
                                                                                  refInFor: true,
                                                                                  staticClass:
                                                                                    "canvas",
                                                                                  attrs:
                                                                                    {
                                                                                      "background-color":
                                                                                        "transparent",
                                                                                      width:
                                                                                        _vm.canvasWidth,
                                                                                      height:
                                                                                        _vm.canvasHeight,
                                                                                      "initial-image":
                                                                                        _vm
                                                                                          .selectedLayout
                                                                                          .settings
                                                                                          .flexibleCanvas,
                                                                                      lock: _vm.isCanvasLocked,
                                                                                      role: "application",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.flexibleSeats,
                                                                    function (
                                                                      seat,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: seat.id,
                                                                          staticClass:
                                                                            "flexible-seat",
                                                                          style:
                                                                            _vm.flexibleSeatStyle(
                                                                              seat
                                                                            ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "seat-assignment",
                                                                            {
                                                                              key:
                                                                                _vm.selectedSeatsKey ===
                                                                                seat.id +
                                                                                  "-select",
                                                                              attrs:
                                                                                {
                                                                                  seat: _vm.constructSeatProp(
                                                                                    seat,
                                                                                    i
                                                                                  ),
                                                                                  student:
                                                                                    _vm.getStudentInSeat(
                                                                                      seat
                                                                                    ) ||
                                                                                    {},
                                                                                  badge:
                                                                                    _vm.constructBadge(
                                                                                      seat
                                                                                    ),
                                                                                  width:
                                                                                    _vm.getSeatWidth(
                                                                                      seat
                                                                                    ),
                                                                                  height:
                                                                                    _vm.getSeatHeight(
                                                                                      seat
                                                                                    ),
                                                                                  "is-coloring":
                                                                                    _vm.isColoring,
                                                                                  "class-color":
                                                                                    _vm.classColor,
                                                                                  "is-flexible-mode": true,
                                                                                  "is-circle":
                                                                                    seat.type ===
                                                                                    "circle",
                                                                                  margin:
                                                                                    "0rem",
                                                                                },
                                                                              on: {
                                                                                "place-student":
                                                                                  _vm.placeStudentArgs,
                                                                                "set-student":
                                                                                  _vm.setStudent,
                                                                                "drag-student":
                                                                                  _vm.dragStudentArgs,
                                                                                "select-student":
                                                                                  _vm.selectStudent,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-container",
                                                            {
                                                              ref: "layout-container",
                                                              staticClass:
                                                                "layout-container fill-height",
                                                              style:
                                                                _vm.layoutContainerStyle,
                                                              attrs: {
                                                                fluid: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  ref: "layout-card",
                                                                  staticClass:
                                                                    "ma-2 pa-2",
                                                                  attrs: {
                                                                    flat: "",
                                                                    justify:
                                                                      "center",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  15,
                                                                  function (
                                                                    row
                                                                  ) {
                                                                    return _c(
                                                                      "v-row",
                                                                      {
                                                                        key:
                                                                          "r" +
                                                                          row,
                                                                        staticClass:
                                                                          "d-flex flex-nowrap",
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            "",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        15,
                                                                        function (
                                                                          seat
                                                                        ) {
                                                                          return _c(
                                                                            "v-col",
                                                                            {
                                                                              key:
                                                                                "s" +
                                                                                seat,
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "seat",
                                                                                  attrs:
                                                                                    {
                                                                                      width:
                                                                                        "5rem",
                                                                                      height:
                                                                                        "5rem",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "perfect-scrollbar",
                                                        {
                                                          style: {
                                                            height:
                                                              _vm.layoutHeight,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "ma-2 pa-2",
                                                              style: {
                                                                "max-width":
                                                                  _vm.layoutMaxWidth,
                                                              },
                                                              attrs: {
                                                                flat: "",
                                                                justify:
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _vm.isColoring
                                                                ? _c(
                                                                    "drag-select",
                                                                    {
                                                                      attrs: {
                                                                        selectorClass:
                                                                          "seat",
                                                                        clear:
                                                                          _vm.clearDragSelect,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.selectSeatsByDrag,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function ({
                                                                                selectedItems,
                                                                              }) {
                                                                                return _vm._l(
                                                                                  _vm.seatSettings,
                                                                                  function (
                                                                                    row,
                                                                                    i
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        key: row[0]
                                                                                          .id,
                                                                                        staticClass:
                                                                                          "d-flex flex-nowrap",
                                                                                        attrs:
                                                                                          {
                                                                                            "no-gutters":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      _vm._l(
                                                                                        row,
                                                                                        function (
                                                                                          seat,
                                                                                          j
                                                                                        ) {
                                                                                          return _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              key: seat.id,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "seat-assignment",
                                                                                                {
                                                                                                  key:
                                                                                                    _vm.selectedSeatsKey ===
                                                                                                    seat.id +
                                                                                                      "-select",
                                                                                                  class:
                                                                                                    _vm.getSeatClass(
                                                                                                      seat,
                                                                                                      selectedItems
                                                                                                    ),
                                                                                                  attrs:
                                                                                                    {
                                                                                                      seat: _vm.constructSeatProp(
                                                                                                        seat,
                                                                                                        i,
                                                                                                        j
                                                                                                      ),
                                                                                                      student:
                                                                                                        _vm.getStudentInSeat(
                                                                                                          seat
                                                                                                        ) ||
                                                                                                        {},
                                                                                                      badge:
                                                                                                        _vm.constructBadge(
                                                                                                          seat
                                                                                                        ),
                                                                                                      "is-coloring":
                                                                                                        _vm.isColoring,
                                                                                                      "is-draggable": false,
                                                                                                      "class-color":
                                                                                                        _vm.classColor,
                                                                                                      "data-seat":
                                                                                                        seat.id,
                                                                                                      margin:
                                                                                                        "0",
                                                                                                    },
                                                                                                  on: {
                                                                                                    "place-student":
                                                                                                      _vm.placeStudentArgs,
                                                                                                    "set-student":
                                                                                                      _vm.setStudent,
                                                                                                    "drag-student":
                                                                                                      _vm.dragStudentArgs,
                                                                                                    "select-student":
                                                                                                      _vm.selectStudent,
                                                                                                    "toggle-seat-selected":
                                                                                                      _vm.toggleSeatSelected,
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                )
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          2692059625
                                                                        ),
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      _vm.seatSettings,
                                                                      function (
                                                                        row,
                                                                        i
                                                                      ) {
                                                                        return _c(
                                                                          "v-row",
                                                                          {
                                                                            key: row[0]
                                                                              .id,
                                                                            staticClass:
                                                                              "d-flex flex-nowrap",
                                                                            attrs:
                                                                              {
                                                                                "no-gutters":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            row,
                                                                            function (
                                                                              seat,
                                                                              j
                                                                            ) {
                                                                              return _c(
                                                                                "v-col",
                                                                                {
                                                                                  key: seat.id,
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "seat-assignment",
                                                                                    {
                                                                                      key:
                                                                                        _vm.selectedSeatsKey ===
                                                                                        seat.id +
                                                                                          "-select",
                                                                                      attrs:
                                                                                        {
                                                                                          seat: _vm.constructSeatProp(
                                                                                            seat,
                                                                                            i,
                                                                                            j
                                                                                          ),
                                                                                          student:
                                                                                            _vm.getStudentInSeat(
                                                                                              seat
                                                                                            ) ||
                                                                                            {},
                                                                                          badge:
                                                                                            _vm.constructBadge(
                                                                                              seat
                                                                                            ),
                                                                                          "is-coloring":
                                                                                            _vm.isColoring,
                                                                                          "class-color":
                                                                                            _vm.classColor,
                                                                                          margin:
                                                                                            "0.1rem",
                                                                                        },
                                                                                      on: {
                                                                                        "place-student":
                                                                                          _vm.placeStudentArgs,
                                                                                        "set-student":
                                                                                          _vm.setStudent,
                                                                                        "drag-student":
                                                                                          _vm.dragStudentArgs,
                                                                                        "select-student":
                                                                                          _vm.selectStudent,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.isColoring
                                        ? _c(
                                            "div",
                                            {
                                              class: _vm.colorPickerClass,
                                              style: {
                                                width: _vm.colorPickerWidth,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            [
                                              _c("v-color-picker", {
                                                staticStyle: {
                                                  "margin-top": "2rem",
                                                },
                                                attrs: {
                                                  value: "",
                                                  "show-swatches":
                                                    _vm.showSwatches,
                                                  "hide-canvas": _vm.hideCanvas,
                                                  mode: "hexa",
                                                },
                                                on: {
                                                  input:
                                                    _vm.setSelectedSeatsColor,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  class: _vm.colorControlsClass,
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click:
                                                          _vm.colorSelectedSeats,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("fal fa-check")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "applyLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click:
                                                          _vm.clearSelectedSeatsColor,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-trash-can"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "clearLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click:
                                                          _vm.clearAllSeatsColor,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-trash-can"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "clearAllLabel",
                                                                { text: "" }
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticStyle: { "z-index": "1" },
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("attendance-subpage", {
                            attrs: {
                              student: _vm.selectedStudent,
                              subject: _vm.subject,
                              assignedSeat: _vm.isAssignedSeat(
                                _vm.selectedStudent
                              ),
                            },
                            on: {
                              "close-drawer": _vm.unselectStudent,
                              "unassign-student": function ($event) {
                                return _vm.unassignStudent(_vm.selectedStudent)
                              },
                              changed: _vm.setAttendanceMap,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("edit-layout", {
        on: {
          "close-layout": _vm.closeLayoutEditor,
          "save-layout": _vm.closeAndSelectNewLayout,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }