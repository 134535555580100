var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: {
        "margin-top": "2rem",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
      },
    },
    [
      _c(
        "div",
        { staticClass: "canvas-container", style: _vm.canvasContainerStyle },
        [
          _c(
            "perfect-scrollbar",
            { ref: "canvas-scroll", style: _vm.canvasScrollStyle },
            [
              _c(
                "div",
                {
                  on: {
                    mousedown: _vm.onCanvasMouseDown,
                    mouseup: _vm.onCanvasMouseUp,
                  },
                },
                [
                  _c("vue-drawing-canvas", {
                    ref: "canvas",
                    staticClass: "canvas",
                    attrs: {
                      "background-color": _vm.backgroundColor,
                      width: _vm.canvasWidth,
                      height: _vm.canvasHeight,
                      "stroke-type": _vm.stroke,
                      eraser: _vm.isErasing,
                      "fill-shape": _vm.areShapesFilled,
                      "line-width": _vm.lineThickness,
                      "initial-image": _vm.initialStrokes,
                      color: _vm.drawColor,
                      lineCap: _vm.selectedLineCap.value,
                      lineJoin: _vm.selectedLineJoin.value,
                    },
                  }),
                  _vm._l(_vm.seats, function (seat, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "flexible-seat",
                        style: _vm.flexibleSeatStyle(seat),
                      },
                      [
                        _c("seat-assignment", {
                          attrs: {
                            seat: _vm.constructSeatProp(seat),
                            student: {},
                            badge: { color: "", icon: "" },
                            width: _vm.getFlexibleSeatWidth(seat),
                            height: _vm.getFlexibleSeatHeight(seat),
                            "is-coloring": false,
                            "class-color": "transparent",
                            "is-flexible-mode": true,
                            "is-circle": seat.type === "circle",
                            margin: "0rem",
                          },
                          on: {
                            "toggle-seat-selected": function ($event) {
                              return _vm.removeSeatIfErasing(seat)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "ml-n8",
              style: { "max-width": _vm.canvasToolbarMaxWidth },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.undo } },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("fal fa-arrow-rotate-left"),
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("undoLabel"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.redo } },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("fal fa-arrow-rotate-right"),
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("redoLabel"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.reset } },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("fal fa-trash-can"),
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("resetLabel"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-4 d-flex flex-nowrap" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { top: "", "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { text: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(_vm._s(_vm.selectedTool.icon)),
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(_vm._s(_vm.selectedTool.title)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                mandatory: "",
                                "aria-label": _vm.$t("drawingToolsLabel"),
                              },
                              model: {
                                value: _vm.selectedToolIdx,
                                callback: function ($$v) {
                                  _vm.selectedToolIdx = $$v
                                },
                                expression: "selectedToolIdx",
                              },
                            },
                            _vm._l(_vm.tools, function (tool, idx) {
                              return _c(
                                "v-list-item",
                                { key: idx },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(_vm._s(tool.icon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(tool.title)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShapeSelected,
                          expression: "isShapeSelected",
                        },
                      ],
                      attrs: { text: "" },
                      on: { click: _vm.toggleFillShapes },
                    },
                    [
                      _vm.areShapesFilled
                        ? _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fas fa-shapes"),
                          ])
                        : _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fal fa-shapes"),
                          ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("fillShapesLabel"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.canDrawSeat,
                          expression: "canDrawSeat",
                        },
                      ],
                      attrs: { text: "" },
                      on: { click: _vm.toggleDrawingSeats },
                    },
                    [
                      _vm.isDrawingSeats
                        ? _c(
                            "div",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fal fa-chair"),
                              ]),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(_vm._s(_vm.$t("drawSeatsLabel"))),
                              ]),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("span", { staticClass: "fa-stack ml-n1" }, [
                              _c("i", {
                                staticClass: "fal fa-ban fa-stack-2x",
                                staticStyle: { "font-size": "1.5rem" },
                              }),
                              _c("i", {
                                staticClass: "fal fa-chair fa-stack-1x",
                                staticStyle: { "font-size": "1rem" },
                              }),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("drawSeatsLabel"))),
                            ]),
                          ]),
                    ]
                  ),
                  _c("v-text-field", {
                    staticClass: "line-thickness-input",
                    attrs: {
                      label: _vm.$t("lineThicknessLabel"),
                      rules: [
                        _vm.rules.lineThicknessRequired,
                        _vm.rules.lineThicknessPositive,
                      ],
                      type: "number",
                      outlined: "",
                      dense: "",
                    },
                    on: {
                      blur: _vm.updateLineThickness,
                      keydown: _vm.updateLineThicknessOnEnter,
                    },
                    model: {
                      value: _vm.lineThicknessInput,
                      callback: function ($$v) {
                        _vm.lineThicknessInput = _vm._n($$v)
                      },
                      expression: "lineThicknessInput",
                    },
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        top: "",
                        "offset-y": "",
                        "close-on-content-click": false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on: onMenu }) {
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isErasing,
                                        expression: "!isErasing",
                                      },
                                    ],
                                    attrs: {
                                      label: _vm.$t("lineColorLabel"),
                                      text: "",
                                    },
                                  },
                                  { ...onMenu }
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fal fa-palette"),
                                  ]),
                                  _c("span", {
                                    staticClass: "ml-2 color-preview",
                                    style: {
                                      "background-color": _vm.drawColor,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("v-color-picker", {
                        attrs: { "show-swatches": "" },
                        model: {
                          value: _vm.drawColor,
                          callback: function ($$v) {
                            _vm.drawColor = $$v
                          },
                          expression: "drawColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { top: "", "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.isShapeSelected,
                                          expression: "!isShapeSelected",
                                        },
                                      ],
                                      attrs: { text: "" },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lineCapLabel", {
                                          choice: _vm.selectedLineCap.title,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                mandatory: "",
                                "aria-label": _vm.$t("lineCapLabel"),
                              },
                              model: {
                                value: _vm.selectedLineCapIdx,
                                callback: function ($$v) {
                                  _vm.selectedLineCapIdx = $$v
                                },
                                expression: "selectedLineCapIdx",
                              },
                            },
                            _vm._l(_vm.lineCapChoices, function (lineCap, idx) {
                              return _c(
                                "v-list-item",
                                { key: idx },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(lineCap.title)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { top: "", "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.isShapeSelected,
                                          expression: "!isShapeSelected",
                                        },
                                      ],
                                      attrs: { text: "" },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lineJoinLabel", {
                                          choice: _vm.selectedLineJoin.title,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                mandatory: "",
                                "aria-label": _vm.$t("lineJoinLabel"),
                              },
                              model: {
                                value: _vm.selectedLineJoinIdx,
                                callback: function ($$v) {
                                  _vm.selectedLineJoinIdx = $$v
                                },
                                expression: "selectedLineJoinIdx",
                              },
                            },
                            _vm._l(
                              _vm.lineJoinChoices,
                              function (lineJoin, idx) {
                                return _c(
                                  "v-list-item",
                                  { key: idx },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(lineJoin.title)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          ref: "layout-container",
          staticClass: "layout-container fill-height",
          style: _vm.layoutContainerStyle,
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card",
            {
              ref: "layout-card",
              staticClass: "ma-2 pa-2",
              attrs: { flat: "" },
            },
            _vm._l(15, function (row) {
              return _c(
                "v-row",
                {
                  key: "r" + row,
                  staticClass: "d-flex flex-nowrap",
                  attrs: { "no-gutters": "" },
                },
                _vm._l(15, function (seat) {
                  return _c(
                    "v-col",
                    { key: "s" + seat },
                    [
                      _c("v-btn", {
                        staticClass: "seat",
                        attrs: { width: "5rem", height: "5rem" },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }