



























































































































































































































































































import { Component, Vue, Emit, Prop, Watch, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import StudentUtils from '@/utils/student-utils';
import moment from 'moment-timezone';
import EditLayout from '@/components/seatingcharts/EditLayout.vue';
import AttendanceSubpage from '@/components/seatingcharts/AttendanceSubpage.vue';
import SeatAssignment from '@/components/seatingcharts/SeatAssignment.vue';
import DragSelect from '@/components/common/DragSelect.vue';
import VueDrawingCanvas from 'vue-drawing-canvas';
import { VForm } from 'vuetify/lib';
import ld from 'lodash';

const seatingcharts = namespace('seatingcharts');
const classes = namespace('classes');
const settings = namespace('settings');
const index = namespace('index');

interface Seat {
  id: number,
  disabled: boolean
}

interface Layout {
  rows: number;
  cols: number;
  id: number;
  name: string;
  settings: {
    seatSettings: any[];
  };
}

@Component({
  components: { EditLayout, AttendanceSubpage, SeatAssignment, DragSelect, VueDrawingCanvas }
})
export default class EditSeating extends Vue {
  @Emit()
  closeSeating() {
    console.log('Closing Seating');
  }

  @Emit()
  saveSeating() {
    console.log('Saving and closing Seating');
  }

  @Prop({ type: Object, required: false })
  originalSeating!: any;

  @Ref('canvas-scroll')
  canvasScroll!: any;

  @Ref('layout-container')
  layoutContainer!: HTMLElement;

  @Ref('layout-card')
  layoutCard!: any;

  @Ref('canvas')
  canvas!: any;

  @Ref('seatingChartForm')
  seatingChartForm!: InstanceType<typeof VForm>;

  @Watch('windowHeight')
  onWindowHeightChanged() {
    if (this.isFlexibleMode) {
      setTimeout(() => {
        this.setCanvasDimensions();
      }, 100);
    }
  }

  @Watch('windowWidth')
  onWindowWidthChanged() {
    if (this.isFlexibleMode) {
      setTimeout(() => {
        this.setCanvasDimensions();
      }, 100);
    }
  }

  @Watch('localSelectedLayout')
  onLocalSelectedLayoutChanged() {
    if (!this.isLoading) {
      this.clearAllSeatsColor();
      this.refreshStudents().then(() => {
        if (this.isFlexibleMode) {
          this.setCanvasDimensions();
          this.setFlexibleSeats();
        }
      });

      this.selectedLayout = this.localSelectedLayout;
    }
  }

  activeColor = '#556270';
  removedColor = '#e4e4e4';
  allDayColor = 'primary';

  title = '';
  isLoading = true;
  selectedLayout: any = null;
  localSelectedLayout: any = null;
  isAddingLayout = false;
  selectedClass: any = null;
  isPickingDateRange = false;
  isColoring = false;
  dateRange: string[] = [];
  seatAssignmentsMap: any = {};
  studentsMap: any = {};
  attendanceMap: any = {};
  selectedSeatsMap: any = {};
  seatColorMap: any = {};
  selectedSeatsKey = '';
  selectedSeatsColor = '';
  selectedStudent: any = {};
  id = -1;
  clearDragSelect = false;
  isCanvasLocked = false;
  canvasHeight = 1;
  canvasWidth = 1;
  canvasScrollHeight = '';
  canvasScrollWidth = '';
  canvasContainerLeft = '';
  canvasContainerTop = '';
  flexibleSeats: any[] = [];

  rules = {
    titleRequired: (value: any) => !!value || this.$t('seatLayoutTitleRequiredMsg'),
    layoutRequired: (value: any) => !!value || this.$t('seatLayoutRequiredMsg')
  };

  @index.Action
  disableResizeable!: () => Promise<any>;

  @index.Action
  enableResizeable!: () => Promise<any>;

  @seatingcharts.Action
  loadRoomLayouts!: () => Promise<any>;

  @seatingcharts.Action
  loadStudents!: (params?: any) => Promise<any>;

  @seatingcharts.Action
  addSeatingAssignment!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: () => Promise<any>;

  @seatingcharts.Getter
  getRoomLayouts!: any[];

  @seatingcharts.Getter
  getStudents!: any[];

  @classes.Getter
  getClassItems!: any[];

  @settings.Getter
  getCurrentYear!: any;

  @settings.Getter
  getDateStyling!: any;

  get windowHeight() {
    return this.$vuetify.breakpoint.height;
  }

  get windowWidth() {
    return this.$vuetify.breakpoint.width;
  }

  get canvasScrollStyle() {
    return {
      width: 'calc(' + this.canvasScrollWidth + ' + 7rem)',
      height: 'calc(' + this.canvasScrollHeight + ' + 7rem)',
      border: 'solid 1px black'
    };
  }

  get layoutMaxWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return '60rem';
      case 'lg': return '50rem';
      case 'md': return '35rem';
      case 'sm': return '30rem';
      case 'xs': return '16rem';
    }
  }

  get layoutHeight() {
    if (this.isColoring) {
      if (this.$vuetify.breakpoint.name === 'sm') {
        return 'calc(100vh - 390px)';
      } else if (this.$vuetify.breakpoint.name === 'xs') {
        return 'calc(100vh - 400px)'
      }
    }

    return 'calc(100vh - 360px)';
  }

  get studentListMaxWidth() {
    if (this.isColoring) {
      return '';
    }

    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return '30rem';
      case 'lg': return '20rem';
      case 'md': return '15rem';
      default: return '';
    }
  }

  get studentCardClass() {
    if (this.isColoring ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'xs') {
      return 'student-card-small px-1';
    } else {
      return 'student-card px-1';
    }
  }

  get studentListClass() {
    return this.isColoring ? 'd-none' : 'hidden-sm-and-down';
  }

  get miniStudentListClass() {
    return this.isColoring ? '' : 'hidden-md-and-up';
  }

  get isFlexibleMode() {
    return (this.localSelectedLayout || { settings: { flexible: false } }).settings.flexible;
  }

  get colorPickerWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg': return '20rem';
      case 'md': return '15rem';
      default: return '';
    }
  }

  get colorPickerClass() {
    if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
      return 'd-flex ml-6';
    } else {
      return '';
    }
  }

  get colorControlsClass() {
    if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
      return 'mt-8';
    } else {
      return 'mt-2';
    }
  }

  get showSwatches() {
    return this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name === 'lg';
  }

  get hideCanvas() {
    return !(this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name === 'lg');
  }

  get dateFormat() {
    let format = 'MM/DD/YYYY';

    if (+this.getDateStyling.dateFormat === 2) {
      format = 'DD/MM/YYYY';
    }

    return format;
  }

  get layouts() {
    return [{ id: 0, name: this.$t('addLayoutLabel') }].concat(this.getRoomLayouts);
  }

  get selectedLayoutIdx() {
    return this.layouts.indexOf(this.localSelectedLayout);
  }

  get subject() {
    if (this.isClassSelected) {
      return this.selectedClass;
    } else {
      return {};
    }
  }

  get isLayoutSelected() {
    return this.localSelectedLayout;
  }

  get isClassSelected() {
    return (this.selectedClass && this.selectedClass.id !== 0);
  }

  get seatSettings() {
    if (this.isLayoutSelected) {
      return this.unflattenSeatSettings(this.localSelectedLayout);
    } else {
      return [];
    }
  }

  get classItems() {
    return [{ id: 0, text: this.$t('allDayLabel') }].concat(this.getClassItems);
  }

  get roomLayoutMenuLabel() {
    if (this.isLayoutSelected) {
      return this.localSelectedLayout.name;
    } else {
      return this.$t('seatLayoutLabel');
    }
  }

  get layoutMenuColor() {
    return this.isLayoutSelected ? this.$vuetify.theme.currentTheme.primary : 'red';
  }

  get classMenuLabel() {
    if (this.isClassSelected) {
      return this.selectedClass.text;
    } else {
      return this.$t('allDayLabel');
    }
  }

  get startDate() {
    return DateTimeUtils.formatToSave(moment(this.dateRange[0]).format(this.dateFormat));
  }

  get endDate() {
    return DateTimeUtils.formatToSave(moment(this.dateRange[1]).format(this.dateFormat));
  }

  get dateRangeText() {
    return DateTimeUtils.formatToShow(this.startDate) + ' - ' + DateTimeUtils.formatToShow(this.endDate);
  }

  get layoutId() {
    if (this.isLayoutSelected) {
      return this.localSelectedLayout.id;
    } else {
      return -1;
    }
  }

  get subjectId() {
    if (this.isClassSelected) {
      return this.selectedClass.value;
    } else {
      return -1;
    }
  }

  get classColor() {
    if (this.isClassSelected) {
      return this.selectedClass.color;
    } else {
      return this.allDayColor;
    }
  }

  get layoutContainerStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return {
        'max-width': '60rem',
        'max-height': 'calc(100vh - 450px)',
        visibility: 'hidden'
      }
      case 'lg': return {
        'max-width': '50rem',
        'max-height': 'calc(100vh - 450px)',
        visibility: 'hidden'
      }
      case 'md': return {
        'max-width': '35rem',
        'max-height': 'calc(100vh - 450px)',
        visibility: 'hidden'
      }
      case 'sm': return {
        'max-width': '27rem',
        'max-height': 'calc(100vh - 560px)',
        visibility: 'hidden'
      }
      case 'xs': return {
        'max-width': '10rem',
        'max-height': 'calc(100vh - 560px)',
        visibility: 'hidden'
      }
    }
  }

  get canvasContainerStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md': return {
        left: 'calc(' + this.canvasContainerLeft + ' - 8rem)',
        top: 'calc(' + this.canvasContainerTop + ' - 8rem)'
      };
      case 'sm':
      case 'xs': return {
        left: 'calc(' + this.canvasContainerLeft + ' - 3rem)',
        top: 'calc(' + this.canvasContainerTop + ' - 7rem)'
      };
    }
  }

  get seatingControlsMaxWidth() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return '15rem';
    } else {
      return '45rem';
    }
  }

  get sortedStudentsMap() {
    return Object.entries(this.studentsMap).sort(([, a]: any, [, b]: any) => {
      const fa = this.getStudentLabel(a).toLowerCase();
      const fb = this.getStudentLabel(b).toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  flexibleSeatStyle(seat: any) {
    return {
      height: seat.seatHeight,
      width: seat.seatWidth,
      left: seat.seatLeft,
      top: seat.seatTop
    }
  }

  async setCanvasDimensions() {
    await this.$nextTick(() => {
      this.canvasHeight = this.layoutCard.$el.scrollHeight + 125;
      this.canvasWidth = this.layoutCard.$el.scrollWidth + 125;
      this.canvasScrollHeight = Math.min(this.layoutCard.$el.offsetHeight, this.layoutContainer.offsetHeight).toString() + 'px';
      this.canvasScrollWidth = Math.min(this.layoutCard.$el.offsetWidth, this.layoutContainer.offsetWidth).toString() + 'px';
      this.canvasContainerLeft = Math.round(this.layoutCard.$el.getBoundingClientRect().left).toString() + 'px';
      this.canvasContainerTop = Math.round(this.layoutCard.$el.getBoundingClientRect().top).toString() + 'px';
    });
  }

  redrawCanvas() {
    this.isCanvasLocked = false;
    this.canvas.redraw();
    this.isCanvasLocked = true;
  }

  getSeatClass(seat: Seat, selectedSeats: any[]) {
    if (seat.disabled) {
      return {
        seat: true,
        active: false
      };
    } else if (CommonUtils.hasValue(this.selectedSeatsMap[seat.id.toString()])) {
      return {
        seat: true,
        active: true
      };
    } else {
      const isActive = !!selectedSeats.find(selectedSeat => {
        return +selectedSeat.$el.dataset.seat === seat.id;
      });

      return {
        seat: true,
        active: isActive
      };
    }
  }

  selectSeatsByDrag(selectedSeats: any[]) {
    this.clearSeatSelection();

    this.clearDragSelect = false;

    for (const seat of selectedSeats) {
      this.selectedSeatsMap[seat.$el.dataset.seat.toString()] = true;
      this.selectedSeatsKey = +seat.$el.dataset.seat + '-select';
    }
  }

  toggleSeatSelected(seat: Seat) {
    if (!seat.disabled) {
      if (CommonUtils.hasValue(this.selectedSeatsMap[seat.id.toString()])) {
        delete this.selectedSeatsMap[seat.id.toString()];
        this.selectedSeatsKey = seat.id + '-unselect';
      } else {
        this.selectedSeatsMap[seat.id.toString()] = true;
        this.selectedSeatsKey = seat.id + '-select';
      }
    }
  }

  setFlexibleSeats() {
    this.flexibleSeats = this.localSelectedLayout.settings.flexibleCanvas.filter((stroke: any) => {
      return stroke.isSeat && !stroke.disabled;
    });
  }

  hasBadge(seat: any) {
    return this.hasStudent(seat) &&
      CommonUtils.hasValue(this.attendanceMap[this.getStudentInSeat(seat).studentId.toString()]);
  }

  getAttendanceBadgeColor(seat: any) {
    if (this.hasBadge(seat)) {
      const student = this.getStudentInSeat(seat);
      const attendanceCode = this.attendanceMap[student.studentId.toString()];

      switch (attendanceCode) {
        case 'P': {
          return 'green';
        }
        case 'T': {
          return 'orange';
        }
        case 'A': {
          return 'red';
        }
        default: {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  getAttendanceBadgeIcon(seat: any) {
    if (this.hasBadge(seat)) {
      const student = this.getStudentInSeat(seat);
      const attendanceCode = this.attendanceMap[student.studentId.toString()];

      switch (attendanceCode) {
        case 'P': {
          return 'fas fa-face-grin-wide';
        }
        case 'T': {
          return 'fas fa-face-meh';
        }
        case 'A': {
          return 'fas fa-face-frown-slight';
        }
        default: {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  startColoring() {
    this.isColoring = true;

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
    this.unselectStudent();
  }

  endColoring() {
    this.isColoring = false;

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
    this.clearSeatSelection();
  }

  setAttendanceMap(attendance: any) {
    this.attendanceMap = attendance;
  }

  constructSeatProp(seat: any, i: any, j?: any) {
    return {
      id: seat.id,
      name: this.isFlexibleMode ? this.$t('flexibleSeatNameLabel', { i: (i + 1) }) : this.$t('seatNameLabel', { r: (i + 1), c: (j + 1) }),
      disabled: seat.disabled,
      color: this.getSeatColor(seat)
    }
  }

  getSeatWidth(seat: any) {
    return this.isFlexibleMode ? seat.seatWidth : '5rem';
  }

  getSeatHeight(seat: any) {
    return this.isFlexibleMode ? seat.seatHeight : '5rem';
  }

  constructBadge(seat: Seat) {
    return {
      color: this.getAttendanceBadgeColor(seat),
      icon: this.getAttendanceBadgeIcon(seat)
    }
  }

  getSeatColor(seat: any) {
    if (this.isFlexibleMode) {
      return seat.color;
    } else if (seat.disabled) {
      return this.removedColor;
    } else if (CommonUtils.hasValue(this.seatColorMap[seat.id.toString()])) {
      return this.seatColorMap[seat.id.toString()];
    } else {
      return this.activeColor;
    }
  }

  setSelectedSeatsColor(value: any) {
    this.selectedSeatsColor = value;
  }

  colorSelectedSeats() {
    this.selectedSeatsKey = '';

    for (const entry of Object.entries(this.selectedSeatsMap)) {
      const seat = entry[0];

      this.seatColorMap[seat.toString()] = this.selectedSeatsColor;
      this.selectedSeatsKey += seat;
    }

    this.selectedSeatsKey += this.selectedSeatsColor;

    this.clearSeatSelection();
  }

  clearSelectedSeatsColor() {
    this.selectedSeatsKey = '';

    for (const entry of Object.entries(this.selectedSeatsMap)) {
      const seat = entry[0];

      delete this.seatColorMap[seat.toString()];
      this.selectedSeatsKey += seat;
    }

    this.selectedSeatsKey += 'clearColor';

    this.clearSeatSelection();
  }

  clearAllSeatsColor() {
    this.seatColorMap = {};

    this.selectedSeatsKey = 'Clear All Seats Color';

    this.clearSeatSelection();
  }

  clearSeatSelection() {
    this.selectedSeatsMap = {};
    this.selectedSeatsKey = 'Clear All Selection';

    this.clearDragSelect = true;
  }

  pickDateRange() {
    this.isPickingDateRange = true;
  }

  finishPickingDateRange() {
    this.isPickingDateRange = false;
  }

  closeLayoutEditor() {
    this.isAddingLayout = false;
    this.localSelectedLayout = null;
  }

  closeAndSelectNewLayout() {
    CommonUtils.showLoading();
    this.isAddingLayout = false;
    this.isLoading = true;

    this.resetSeating();

    this.loadRoomLayouts().then(() => {
      this.isLoading = false;
      CommonUtils.hideLoading();

      this.localSelectedLayout = this.layouts[this.layouts.length - 1]
    });
  }

  hasPhoto(student: any) {
    return CommonUtils.hasText(student.photoUrl);
  }

  hasStudent(seat: Seat) {
    return CommonUtils.hasValue(this.seatAssignmentsMap[seat.id.toString()]);
  }

  isAssignedSeat(student: any) {
    return student.assignedSeat !== -1;
  }

  get getInitials() {
    return StudentUtils.getStudentInitials;
  }

  getStudentLabel(student: any) {
    return StudentUtils.getStudentLabel(student);
  }

  getStudentInSeat(seat: Seat) {
    const studentId = this.seatAssignmentsMap[seat.id.toString()];

    if (studentId) {
      return this.studentsMap[studentId.toString()];
    }

    return null;
  }

  selectStudent(student: any) {
    if (!this.isColoring) {
      this.selectedStudent = student;

      if (this.isFlexibleMode) {
        this.setCanvasDimensions();
      }
    }
  }

  setStudent(seat: any) {
    if (this.selectedStudent.studentId && !seat.disabled) {
      this.assignStudent(this.selectedStudent, seat);
    }
  }

  unselectStudent() {
    this.selectedStudent = {};

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
  }

  dragStudentArgs(args: { event: DragEvent, student: any }) {
    this.dragStudent(args.event, args.student);
  }

  dragStudent(event: DragEvent, student: any) {
    if (event.dataTransfer !== null) {
      event.dataTransfer.setData('text/plain', JSON.stringify(student));
    }
  }

  placeStudentArgs(args: { event: DragEvent, seat: any }) {
    this.placeStudent(args.event, args.seat);
  }

  placeStudent(event: DragEvent, seat?: any) {
    if (event.dataTransfer !== null) {
      const student = JSON.parse(event.dataTransfer.getData('text/plain'));

      if (seat) {
        this.assignStudent(student, seat);
        this.getStudentInSeat(seat);
      } else {
        this.unassignStudent(student);
        this.unselectStudent();
      }

      if (this.isFlexibleMode) {
        this.setCanvasDimensions();
      }
    }
  }

  shuffleSeating() {
    this.resetSeating();

    let seats: Seat[] = [];

    if (this.isFlexibleMode) {
      seats = this.flexibleSeats;
    } else {
      seats = this.seatSettings.flat().filter(seat => !seat.disabled);
    }

    const shuffledStudents = this.shuffle(Object.values(this.studentsMap));
    const minLength = Math.min(seats.length, shuffledStudents.length);

    for (let i = 0; i < minLength; i++) {
      this.assignStudent(shuffledStudents[i], seats[i]);
    }

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
  }

  shuffleSeatingByGender() {
    this.resetSeating();

    let flattenedSeatSettings: Seat[] = [];
    let seats: Seat[] = [];

    if (this.isFlexibleMode) {
      flattenedSeatSettings = this.flexibleSeats;
      seats = flattenedSeatSettings;
    } else {
      flattenedSeatSettings = this.seatSettings.flat();
      seats = flattenedSeatSettings.filter(seat => !seat.disabled);
    }

    const shuffledStudents = this.shuffle(Object.values(this.studentsMap));

    const maleFemale = ['M', 'F'].sort(() => 0.5 - Math.random());

    for (const student of shuffledStudents) {
      for (const seat of seats) {
        if (!this.hasStudent(seat)) {
          const gender = student.gender;

          if (gender && gender !== '' && gender !== 'U') {
            const isEven = maleFemale.indexOf(gender) % 2 === 0;

            if (this.canPlaceHereByGender(flattenedSeatSettings.indexOf(seat), isEven)) {
              this.assignStudent(student, seat);
              break;
            }
          } else {
            this.assignStudent(student, seat);
            break;
          }
        }
      }
    }

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
  }

  assignStudent(student: any, seat: Seat) {
    if (this.isAssignedSeat(student)) {
      delete this.seatAssignmentsMap[student.assignedSeat.toString()];
    }

    if (this.hasStudent(seat)) {
      const studentToSwap = this.getStudentInSeat(seat);

      if (this.isAssignedSeat(student)) {
        this.seatAssignmentsMap[student.assignedSeat.toString()] = studentToSwap.studentId;
      }

      studentToSwap.assignedSeat = student.assignedSeat;
      this.studentsMap[studentToSwap.studentId.toString()] = studentToSwap;
    }

    this.seatAssignmentsMap[seat.id.toString()] = student.studentId;
    student.assignedSeat = seat.id
    this.studentsMap[student.studentId.toString()] = student;

    this.seatAssignmentsMap = ld.clone(this.seatAssignmentsMap);
    this.studentsMap = ld.clone(this.studentsMap);
  }

  unassignStudent(student: any) {
    if (this.isAssignedSeat(student)) {
      delete this.seatAssignmentsMap[student.assignedSeat.toString()];

      this.seatAssignmentsMap = ld.clone(this.seatAssignmentsMap);
    }

    student.assignedSeat = -1;
    this.studentsMap[student.studentId.toString()] = student;

    this.studentsMap = ld.clone(this.studentsMap);
  }

  async refreshStudents() {
    CommonUtils.showLoading();
    this.isLoading = true;

    this.studentsMap = {};
    this.seatAssignmentsMap = {};
    if (this.selectedStudent.studentId) {
      this.unselectStudent();
    }

    let params = {};

    if (this.isClassSelected) {
      params = { subjectId: this.subjectId, color: this.classColor };
    }

    return this.loadStudents(params).then(() => {
      for (const student of this.getStudents) {
        if (student && (!this.isClassSelected || student.inSubject === 'Y')) {
          student.assignedSeat = -1;
          this.studentsMap[student.studentId.toString()] = student;
        }
      }

      this.isLoading = false;
      CommonUtils.hideLoading();
    });
  }

  resetSeating() {
    this.unselectStudent();

    for (const id in this.studentsMap) {
      const student = this.studentsMap[id];

      this.unassignStudent(student);
      student.assignedSeat = -1;
    }

    if (this.isFlexibleMode) {
      this.setCanvasDimensions();
    }
  }

  async addSeating() {
    const form: any = this.$refs.seatingChartForm;
    const dateObserver: any = this.$refs.observer;
    const dateFieldsValid = await dateObserver.validate();
    if (form.validate() && dateFieldsValid && this.title && this.title !== '' && this.isLayoutSelected) {
      CommonUtils.showLoading();
      this.isLoading = true;

      const seatSettings: any[] = [];

      for (const [id, assignedId] of Object.entries(this.seatAssignmentsMap)) {
        const color = this.seatColorMap[id.toString()];
        const seat = { id: parseInt(id), assignedId: assignedId, color: color !== undefined ? color : '' };

        seatSettings.push(seat);
      }

      for (const [id, color] of Object.entries(this.seatColorMap)) {
        if (!CommonUtils.hasValue(this.seatAssignmentsMap[id])) {
          const seat = { id: parseInt(id), assignedId: 0, color: color };

          seatSettings.push(seat);
        }
      }

      const seatingAssignment: any = {
        start: this.startDate,
        end: this.endDate,
        name: this.title,
        layoutId: this.layoutId,
        subjectId: this.subjectId,
        yearId: this.getCurrentYear.yearId,
        extraClass: 0,
        settings: {
          seatSettings: seatSettings
        }
      }

      if (this.id !== -1) {
        seatingAssignment.id = this.id;
      }

      this.addSeatingAssignment(seatingAssignment).then(() => {
        this.isLoading = false;
        CommonUtils.hideLoading();

        this.saveSeating();
      });
    }
  }

  private canPlaceHereByGender(idx: number, isGenderEven: boolean) {
    const isColEven = (idx % this.localSelectedLayout.cols) % 2 === 0;

    if (isGenderEven) {
      return isColEven;
    } else {
      return !isColEven;
    }
  }

  private shuffle(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const randIdx = Math.floor(Math.random() * (i + 1));

      [array[i], array[randIdx]] = [array[randIdx], array[i]];
    }

    return array;
  }

  private unflattenSeatSettings(layout: Layout) {
    const unflattenedArray: Seat[][] = [];

    for (let i = 0; i < layout.rows; i++) {
      unflattenedArray.push([]);

      for (let j = 0; j < layout.cols; j++) {
        const seat = layout.settings.seatSettings[(i * layout.cols) + j];

        unflattenedArray[i].push(seat);
      }
    }

    return unflattenedArray;
  }

  initialize() {
    CommonUtils.showLoading();
    this.isLoading = true;

    if (this.originalSeating) {
      this.title = this.originalSeating.name;
      this.dateRange = [
        moment(this.originalSeating.start, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        moment(this.originalSeating.end, 'MM/DD/YYYY').format('YYYY-MM-DD')
      ];
      this.id = this.originalSeating.id;
    } else {
      this.dateRange = [
        moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY').format('YYYY-MM-DD')
      ];
    }

    this.loadRoomLayouts().then(() => {
      if (this.originalSeating) {
        this.localSelectedLayout = this.getRoomLayouts.find((l) => {
          return l.id === this.originalSeating.layoutId;
        })
      }

      this.loadClasses().then(() => {
        if (this.originalSeating) {
          this.selectedClass = this.getClassItems.find((ci) => ci.value === this.originalSeating.subjectId);
        }

        let params = {};

        if (this.isClassSelected) {
          params = { subjectId: this.subjectId, color: this.classColor };
        } else {
          this.selectedClass = this.classItems[0]
        }

        this.loadStudents(params).then(() => {
          for (const student of this.getStudents) {
            if (!!student && (!this.isClassSelected || student.inSubject === 'Y')) {
              student.assignedSeat = -1;
              this.studentsMap[student.studentId.toString()] = student;
            }
          }

          if (this.originalSeating) {
            for (const seat of this.originalSeating.settings.seatSettings) {
              const student = this.studentsMap[seat.assignedId.toString()];

              if (student) {
                this.seatAssignmentsMap[seat.id.toString()] = seat.assignedId;
                student.assignedSeat = seat.id;
                this.studentsMap[seat.assignedId.toString()] = student;
              }

              if (seat.color && seat.color !== '') {
                this.seatColorMap[seat.id.toString()] = seat.color;
              }
            }
          }

          this.isLoading = false;

          if (this.isFlexibleMode) {
            this.setCanvasDimensions();
            this.setFlexibleSeats();
          }

          this.selectedLayout = this.localSelectedLayout;

          CommonUtils.hideLoading();
        });
      });
    });
  }

  created() {
    this.initialize();
  }
}
