var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isStudentSelected
        ? _c(
            "v-card",
            {
              staticClass: "mx-auto hidden-lg-and-down",
              attrs: { width: "25rem", height: "100rem", elevation: "0" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            icon: "",
                            depressed: "",
                            label: _vm.$t("closeLabel")
                          },
                          on: { click: _vm.closeDrawer }
                        },
                        [_c("v-icon", [_vm._v("fal fa-circle-xmark")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    [
                      _vm.assignedSeat
                        ? _c(
                            "pb-btn",
                            {
                              attrs: {
                                icon: "",
                                depressed: "",
                                label: _vm.$t("clearLabel")
                              },
                              on: { click: _vm.unassignStudent }
                            },
                            [
                              _c("v-icon", [
                                _vm._v("fal fa-right-from-bracket")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: {
                            size: "25rem",
                            tile: "",
                            color: _vm.subjectColor
                          }
                        },
                        [
                          _vm.hasPhoto
                            ? _c("v-img", {
                                attrs: { src: _vm.student.photoUrl }
                              })
                            : _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(_vm.initials))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { align: "center" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.studentLabel))])
                  ])
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-6" }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center" } },
                    [
                      _c("p", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("attendanceLabel")))
                        ])
                      ]),
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("fal fa-calendar-day")]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.todayLabel))])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isLessonsTodayEmpty
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("attendanceNoClassDayLabel")))
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : !_vm.isSubjectInLessonsToday
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("classNotTodayLabel", {
                                  s: _vm.subject.text
                                })
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("presentLabel")
                              },
                              on: { click: _vm.setPresent }
                            },
                            [
                              _vm.isPresent
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "green" } },
                                    [_vm._v("fas fa-face-grin-wide")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-grin-wide")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("presentLabel")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("tardyLabel")
                              },
                              on: { click: _vm.setTardy }
                            },
                            [
                              _vm.isTardy
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "orange" } },
                                    [_vm._v("fas fa-face-meh")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-meh")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("tardyLabel")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("absentLabel")
                              },
                              on: { click: _vm.setAbsent }
                            },
                            [
                              _vm.isAbsent
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "red" } },
                                    [_vm._v("fas fa-face-frown-slight")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-frown-slight")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("absentLabel")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "hidden-xl-only",
          attrs: {
            value: _vm.isStudentSelected,
            absolute: "",
            "hide-overlay": "",
            temporary: "",
            right: "",
            permanent: _vm.isStudentSelected,
            width: "25rem"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isStudentSelected,
                  expression: "isStudentSelected"
                }
              ]
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-nowrap" },
                    [
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            icon: "",
                            depressed: "",
                            label: _vm.$t("closeLabel")
                          },
                          on: { click: _vm.closeDrawer }
                        },
                        [_c("v-icon", [_vm._v("fal fa-circle-xmark")])],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.assignedSeat
                        ? _c(
                            "pb-btn",
                            {
                              attrs: {
                                icon: "",
                                depressed: "",
                                label: _vm.$t("clearLabel")
                              },
                              on: { click: _vm.unassignStudent }
                            },
                            [
                              _c("v-icon", [
                                _vm._v("fal fa-right-from-bracket")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: {
                            size: "25rem",
                            tile: "",
                            color: _vm.subjectColor
                          }
                        },
                        [
                          _vm.hasPhoto
                            ? _c("v-img", {
                                attrs: { src: _vm.student.photoUrl }
                              })
                            : _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(_vm.initials))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { align: "center" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.studentLabel))])
                  ])
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-6" }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center" } },
                    [
                      _c("p", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("attendanceLabel")))
                        ])
                      ]),
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("fal fa-calendar-day")]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.todayLabel))])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isLessonsTodayEmpty
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("attendanceNoClassDayLabel")))
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : !_vm.isSubjectInLessonsToday
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("classNotTodayLabel", {
                                  s: _vm.subject.text
                                })
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "border-padding",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("presentLabel")
                              },
                              on: { click: _vm.setPresent }
                            },
                            [
                              _vm.isPresent
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "green" } },
                                    [_vm._v("fas fa-face-grin-wide")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-grin-wide")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("presentLabel")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "border-padding",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("tardyLabel")
                              },
                              on: { click: _vm.setTardy }
                            },
                            [
                              _vm.isTardy
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "orange" } },
                                    [_vm._v("fas fa-face-meh")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-meh")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("tardyLabel")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "border-padding",
                              attrs: {
                                icon: "",
                                "aria-label": _vm.$t("absentLabel")
                              },
                              on: { click: _vm.setAbsent }
                            },
                            [
                              _vm.isAbsent
                                ? _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "red" } },
                                    [_vm._v("fas fa-face-frown-slight")]
                                  )
                                : _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fal fa-face-frown-slight")
                                  ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.$t("absentLabel")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ]
      ),
      _c(
        "v-alert",
        {
          staticClass: "attendance-updated-alert",
          attrs: {
            type: "success",
            value: _vm.showUpdateSuccess,
            transition: "fade-transition"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("attendanceUpdatedMsg")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }