































































































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import VueDrawingCanvas from 'vue-drawing-canvas';
import SeatAssignment from '@/components/seatingcharts/SeatAssignment.vue';

interface Seat {
  id: number,
  disabled: boolean
}

enum Tool {
  Draw,
  Erase,
  Line,
  Circle,
  Rectangle,
  Triangle,
  RightTriangle
}

@Component({ components: { VueDrawingCanvas, SeatAssignment } })
export default class FlexibleLayout extends Vue {
  @Prop({ type: Array, required: true })
  seatSettings!: Seat[][];

  @Prop({ type: String, required: true })
  activeColor!: string;

  @Prop({ type: String, required: true })
  removedColor!: string;

  @Prop({ type: Array, required: true })
  initialStrokes!: any[];

  @Ref('canvas-scroll')
  canvasScroll!: any;

  @Ref('layout-container')
  layoutContainer!: HTMLElement;

  @Ref('layout-card')
  layoutCard!: any;

  @Ref('canvas')
  canvas!: any;

  @Watch('windowHeight')
  onWindowHeightChanged() {
    setTimeout(() => {
      this.setCanvasDimensions();
    }, 100);
  }

  @Watch('windowWidth')
  onWindowWidthChanged() {
    setTimeout(() => {
      this.setCanvasDimensions();
    }, 100);
  }

  canvasHeight = 0;
  canvasWidth = 0;
  canvasScrollHeight = '';
  canvasScrollWidth = '';
  canvasContainerLeft = '';
  canvasContainerTop = '';
  selectedToolIdx = 0;
  selectedLineCapIdx = 0;
  selectedLineJoinIdx = 1;
  backgroundColor = 'transparent';
  areShapesFilled = false;
  lineThickness = 5;
  lineThicknessInput = 5;
  drawColor = '#000000ff';
  isDrawingSeats = false;
  seats: any[] = [];

  tools = [
    { title: this.$t('drawLabel'), icon: 'fal fa-pencil', value: Tool.Draw },
    { title: this.$t('eraseLabel'), icon: 'fal fa-eraser', value: Tool.Erase },
    { title: this.$t('lineLabel'), icon: 'fal fa-slash', value: Tool.Line },
    { title: this.$t('circleLabel'), icon: 'fal fa-circle', value: Tool.Circle },
    { title: this.$t('rectangleLabel'), icon: 'fal fa-square', value: Tool.Rectangle },
    { title: this.$t('triangleLabel'), icon: 'fal fa-triangle', value: Tool.Triangle },
    { title: this.$t('rightTriangleLabel'), icon: 'fal fa-triangle', value: Tool.RightTriangle }
  ];

  lineCapChoices = [
    { title: this.$t('roundLabel'), value: 'round' },
    { title: this.$t('squareLabel'), value: 'square' },
    { title: this.$t('buttLabel'), value: 'butt' }
  ];

  lineJoinChoices = [
    { title: this.$t('roundLabel'), value: 'round' },
    { title: this.$t('miterLabel'), value: 'miter' },
    { title: this.$t('bevelLabel'), value: 'bevel' }
  ];

  rules = {
    lineThicknessRequired: (value: any) => !!value || this.$t('lineThicknessRequiredMsg'),
    lineThicknessPositive: (value: number) => value > 0 || this.$t('lineThicknessPositiveMsg')
  }

  get strokes() {
    return this.canvas.getAllStrokes();
  }

  get selectedTool() {
    return this.tools[this.selectedToolIdx];
  }

  get selectedLineCap() {
    return this.lineCapChoices[this.selectedLineCapIdx];
  }

  get selectedLineJoin() {
    return this.lineJoinChoices[this.selectedLineJoinIdx];
  }

  get isErasing() {
    return this.selectedTool.value === Tool.Erase;
  }

  get isShapeSelected() {
    return this.selectedTool.value === Tool.Circle ||
      this.selectedTool.value === Tool.Rectangle ||
      this.selectedTool.value === Tool.Triangle ||
      this.selectedTool.value === Tool.RightTriangle;
  }

  get canDrawSeat() {
    return this.selectedTool.value === Tool.Circle ||
      this.selectedTool.value === Tool.Rectangle;
  }

  get stroke() {
    if (this.selectedTool.value === Tool.Erase) {
      this.backgroundColor = 'white';

      return 'dash';
    }

    this.backgroundColor = 'transparent';

    if (this.selectedTool.value === Tool.Draw) {
      return 'dash';
    } else if (this.selectedTool.value === Tool.Line) {
      return 'line';
    } else if (this.selectedTool.value === Tool.Circle) {
      return 'circle';
    } else if (this.selectedTool.value === Tool.Rectangle) {
      return 'square';
    } else if (this.selectedTool.value === Tool.Triangle) {
      return 'triangle';
    } else if (this.selectedTool.value === Tool.RightTriangle) {
      return 'half_triangle';
    }
  }

  get windowHeight() {
    return this.$vuetify.breakpoint.height;
  }

  get windowWidth() {
    return this.$vuetify.breakpoint.width;
  }

  get layoutContainerStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return {
        'max-width': '80rem',
        'max-height': 'calc(100vh - 400px)',
        visibility: 'hidden'
      };
      case 'lg': return {
        'max-width': '50rem',
        'max-height': 'calc(100vh - 400px)',
        visibility: 'hidden'
      };
      case 'md': return {
        'max-width': '45rem',
        'max-height': 'calc(100vh - 400px)',
        visibility: 'hidden'
      };
      case 'sm': return {
        'max-width': '27rem',
        'max-height': 'calc(100vh - 400px)',
        visibility: 'hidden'
      };
      case 'xs': return {
        'max-width': '10rem',
        'max-height': 'calc(100vh - 500px)',
        visibility: 'hidden'
      };
    }
  }

  get canvasContainerStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md': return {
        top: 'calc(' + this.canvasContainerTop + ' - 8rem)'
      };
      case 'sm': return {
        top: 'calc(' + this.canvasContainerTop + ' - 8rem)'
      };
      case 'xs': return {
        top: 'calc(' + this.canvasContainerTop + ' - 7rem)'
      };
    }
  }

  get canvasScrollStyle() {
    let width;
    if (this.$vuetify.breakpoint.xsOnly) {
      width = 11;
    } else if (this.$vuetify.breakpoint.smOnly) {
      width = 14;
    } else {
      width = 7;
    }
    return {
      width: 'calc(' + this.canvasScrollWidth + ` + ${width}rem)`,
      height: 'calc(' + this.canvasScrollHeight + ' + 7rem)',
      border: 'solid 1px black'
    };
  }

  get canvasToolbarMaxWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg': return '70rem';
      case 'md': return '60rem';
      case 'sm': return '40rem';
      case 'xs': return '20rem';
    }
  }

  onCanvasMouseDown() {
    if (this.isErasing) {
      this.setBackgroundColor('transparent');
    }
  }

  onCanvasMouseUp() {
    if (this.isErasing) {
      this.setBackgroundColor('white');
    } else if (this.isDrawingSeats && this.isShapeSelected) {
      const seat = this.getLastStroke();

      if (seat !== this.seats[this.seats.length - 1] && seat.coordinates.length !== 0) {
        seat.isSeat = true;
        seat.seatWidth = this.getFlexibleSeatWidth(seat);
        seat.seatHeight = this.getFlexibleSeatHeight(seat);
        seat.seatLeft = this.getFlexibleSeatLeft(seat);
        seat.seatTop = this.getFlexibleSeatTop(seat);

        this.seats.push(seat);
      }
    }
  }

  flexibleSeatStyle(seat: any) {
    return {
      height: this.getFlexibleSeatHeight(seat),
      width: this.getFlexibleSeatWidth(seat),
      left: this.getFlexibleSeatLeft(seat),
      top: this.getFlexibleSeatTop(seat)
    }
  }

  getFlexibleSeatHeight(seat: any) {
    switch (seat.type) {
      case 'square': return Math.abs(seat.coordinates[1].y - seat.coordinates[0].y) + 'px'
      case 'circle': return (seat.coordinates[1].y * 2) + 'px';
    }
  }

  getFlexibleSeatWidth(seat: any) {
    switch (seat.type) {
      case 'square': return Math.abs(seat.coordinates[0].x - seat.coordinates[2].x) + 'px';
      case 'circle': return (seat.coordinates[1].x * 2) + 'px';
    }
  }

  getFlexibleSeatLeft(seat: any) {
    switch (seat.type) {
      case 'square': {
        const minX = Math.min(seat.coordinates[0].x, seat.coordinates[1].x,
          seat.coordinates[2].x, seat.coordinates[3].x);

        return minX + 'px';
      }
      case 'circle': return (seat.coordinates[0].x - seat.coordinates[1].x) + 'px';
    }
  }

  getFlexibleSeatTop(seat: any) {
    switch (seat.type) {
      case 'square': {
        const minY = Math.min(seat.coordinates[0].y, seat.coordinates[1].y,
          seat.coordinates[2].y, seat.coordinates[3].y);

        return minY + 'px';
      }
      case 'circle': return (seat.from.y - seat.coordinates[1].y) + 'px';
    }
  }

  constructSeatProp(seat: any) {
    return {
      id: seat.id,
      disabled: seat.disabled,
      color: seat.color
    }
  }

  removeSeatIfErasing(seatToRemove: any) {
    if (this.selectedTool.value === Tool.Erase) {
      this.seats = this.seats.filter((elem: any) => {
        return elem !== seatToRemove;
      });

      for (const seat of this.strokes) {
        if (seat === seatToRemove) {
          seat.isSeat = false;
          break;
        }
      }
    }
  }

  getLastStroke() {
    return this.strokes[this.strokes.length - 1];
  }

  undo() {
    this.backgroundColor = 'transparent';

    const lastStroke = this.getLastStroke();

    if (lastStroke.isSeat) {
      this.seats = this.seats.filter((seat: any) => {
        return seat !== lastStroke;
      });
    }

    setTimeout(() => {
      this.canvas.undo();
    }, 50);
  }

  redo() {
    this.backgroundColor = 'transparent';

    const currentLastStroke = this.getLastStroke();

    setTimeout(() => {
      this.canvas.redo();

      const lastStroke = this.getLastStroke();

      if (lastStroke.isSeat && lastStroke !== currentLastStroke) {
        this.seats.push(lastStroke);
      }
    }, 50);
  }

  // redraw() {
  //   this.backgroundColor = 'transparent';

  //   setTimeout(() => {
  //     this.canvas.redraw();
  //   }, 50);
  // }

  reset() {
    this.backgroundColor = 'transparent';

    this.seats = [];

    setTimeout(() => {
      this.canvas.reset();
    }, 50);
  }

  toggleFillShapes() {
    this.areShapesFilled = !this.areShapesFilled;
  }

  toggleDrawingSeats() {
    this.isDrawingSeats = !this.isDrawingSeats;
  }

  updateLineThicknessOnEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.updateLineThickness();
    }
  }

  updateLineThickness() {
    if (this.lineThicknessInput && this.lineThicknessInput > 0) {
      this.lineThickness = this.lineThicknessInput;
    }
  }

  setBackgroundColor(color: string) {
    this.backgroundColor = color;
  }

  setCanvasDimensions() {
    this.canvasHeight = this.layoutCard.$el.scrollHeight + 125;
    this.canvasWidth = this.layoutCard.$el.scrollWidth + 125;
    this.canvasScrollHeight = Math.min(this.layoutCard.$el.offsetHeight, this.layoutContainer.offsetHeight).toString() + 'px';
    this.canvasScrollWidth = Math.min(this.layoutCard.$el.offsetWidth, this.layoutContainer.offsetWidth).toString() + 'px';
    this.canvasContainerLeft = Math.round(this.layoutCard.$el.getBoundingClientRect().left).toString() + 'px';
    this.canvasContainerTop = Math.round(this.layoutCard.$el.getBoundingClientRect().top).toString() + 'px';
  }

  mounted() {
    this.setCanvasDimensions();

    if (this.initialStrokes.length !== 0) {
      this.seats = this.initialStrokes.filter((stroke: any) => {
        return stroke.isSeat;
      });
    }
  }
}
