





































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import StudentUtils from '@/utils/student-utils';
import moment from 'moment-timezone';
import ld from 'lodash';

const attendance = namespace('attendance');
const settings = namespace('settings');

@Component
export default class AttendanceSubpage extends Vue {
  @Emit()
  closeDrawer() {
    console.log('Closing attendance drawer');
  }

  @Emit()
  unassignStudent() {
    console.log('unassigning student');
  }

  @Emit()
  changed() {
    return this.attendanceMap;
  }

  @Prop({ type: Object, required: false })
  student!: any;

  @Prop({ type: Object, required: false })
  subject!: any;

  @Prop({ type: Boolean, default: false })
  assignedSeat!: boolean;

  @Watch('student')
  onStudentChanged(newStudent: any) {
    if (!ld.isEmpty(newStudent)) {
      switch (this.attendanceMap[newStudent.studentId.toString()]) {
        case 'P': {
          this.isPresent = true;
          this.isTardy = false;
          this.isAbsent = false;
          break;
        }
        case 'T': {
          this.isPresent = false;
          this.isTardy = true;
          this.isAbsent = false;
          break;
        }
        case 'A': {
          this.isPresent = false;
          this.isTardy = false;
          this.isAbsent = true;
          break;
        }
        default: {
          this.isPresent = false;
          this.isTardy = false;
          this.isAbsent = false;
          break;
        }
      }
    }
  }

  @Watch('subject')
  onSubjectChanged() {
    this.refresh();
  }

  allDayColor = 'primary';
  isLoading = true;
  attendanceMap: any = {};
  isPresent = false;
  isTardy = false;
  isAbsent = false;
  showUpdateSuccess = false;
  updateAlertTimeoutId = 0;

  @attendance.Action
  loadStudents!: (params?: any) => Promise<any>;

  @attendance.Action
  addAttendance!: (params?: any) => Promise<any>;

  @attendance.Action
  loadLessonsByDate!: (params?: any) => Promise<any>;

  @attendance.Getter
  getStudents!: any[];

  @attendance.Getter
  getLessons!: any[];

  @settings.Getter
  getDateStyling!: any;

  get today() {
    return DateTimeUtils.formatToSave(moment().format(this.dateFormat));
  }

  get todayLabel() {
    return DateTimeUtils.formatToShow(moment().format('MM/DD/YYYY'));
  }

  get dateFormat() {
    let format = 'MM/DD/YYYY';

    if (+this.getDateStyling.dateFormat === 2) {
      format = 'DD/MM/YYYY';
    }

    return format;
  }

  get students() {
    return this.getStudents;
  }

  get lessonsToday() {
    return this.getLessons;
  }

  get isSubjectInLessonsToday() {
    return !this.isSubjectSelected ||
      this.lessonsToday.find(lesson => lesson.subjectId === this.subject.value) !== undefined;
  }

  get isLessonsTodayEmpty() {
    return this.lessonsToday.length === 0;
  }

  get isStudentSelected() {
    return !!this.student.studentId;
  }

  get isSubjectSelected() {
    return !!this.subject.value;
  }

  get hasPhoto() {
    return this.student.photoUrl !== '';
  }

  get initials() {
    return StudentUtils.getStudentInitials(this.student);
  }

  get studentLabel() {
    return StudentUtils.getStudentLabel(this.student);
  }

  get subjectColor() {
    if (this.isSubjectSelected && this.subject.color !== '') {
      return this.subject.color;
    } else {
      return this.allDayColor;
    }
  }

  get attendanceCode() {
    if (CommonUtils.hasValue(this.attendanceMap[this.student.studentId.toString()])) {
      return this.attendanceMap[this.student.studentId.toString()];
    } else {
      return '';
    }
  }

  setPresent() {
    this.attendanceMap[this.student.studentId.toString()] = 'P';
    this.isPresent = true;
    this.isTardy = false;
    this.isAbsent = false;

    this.updateAttendance();
  }

  setTardy() {
    this.attendanceMap[this.student.studentId.toString()] = 'T';
    this.isPresent = false;
    this.isTardy = true;
    this.isAbsent = false;

    this.updateAttendance();
  }

  setAbsent() {
    this.attendanceMap[this.student.studentId.toString()] = 'A';
    this.isPresent = false;
    this.isTardy = false;
    this.isAbsent = true;

    this.updateAttendance();
  }

  updateAttendance() {
    CommonUtils.showLoading();
    this.isLoading = true;

    const request = new URLSearchParams();

    for (const student of this.students) {
      if (CommonUtils.hasValue(this.attendanceMap[student.studentId.toString()])) {
        request.append('students', JSON.stringify({
          id: student.studentId,
          code: this.attendanceMap[student.studentId.toString()]
        }));
      } else {
        request.append('students', JSON.stringify({ id: student.studentId }));
      }
    }

    request.append('attendanceDate', this.today);
    request.append('subjects', JSON.stringify({
      id: this.isSubjectSelected ? this.subject.value : 0,
      extraClass: 0
    }));

    this.addAttendance(request).then(() => {
      CommonUtils.hideLoading();
      this.isLoading = false;

      this.changed();

      clearTimeout(this.updateAlertTimeoutId);
      this.showUpdateSuccess = true;
      this.updateAlertTimeoutId = setTimeout(() => {
        this.showUpdateSuccess = false;
      }, 2000);
    });
  }

  refresh() {
    CommonUtils.showLoading();
    this.isLoading = true;

    this.attendanceMap = {};

    this.loadLessonsByDate({
      attendanceDate: this.today
    }).then(() => {
      this.loadStudents({
        subjectIds: this.isSubjectSelected ? this.subject.value : 0,
        attendanceDate: this.today,
        extraClass: 0
      }).then(() => {
        for (const student of this.students) {
          if (student.attendanceCode && student.attendanceCode !== '') {
            this.attendanceMap[student.studentId.toString()] = student.attendanceCode;
          }
        }

        this.changed();

        CommonUtils.hideLoading();
        this.isLoading = false;
      });
    });
  }

  initialize() {
    this.refresh();
  }

  created() {
    this.initialize();
  }
}
