
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import FlexibleLayout from '@/components/seatingcharts/FlexibleLayout.vue';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { VForm } from 'vuetify/lib';

const seatingcharts = namespace('seatingcharts');

interface Seat {
  id: number,
  disabled: boolean
}

interface Layout {
  cols: number,
  name: string,
  rows: number,
  id?: number,
  settings: {
    flexible?: boolean,
    seatSettings: Seat[],
    flexibleCanvas: any[]
  }
}

@Component({
  components: { FlexibleLayout }
})
export default class EditLayout extends Vue {
  @Emit()
  closeLayout() {
    console.log('Closing Layout');
  }

  @Emit()
  saveLayout() {
    console.log('Saved and Closing Layout');
  }

  @Prop({ type: Object, required: false })
  originalLayout!: any;

  $refs!: {
    seatingLayoutForm: InstanceType<typeof VForm>,
    flexibleLayout: any
  }

  localRefreshKey = CommonUtils.generateUUID();

  activeColor = '#556270';
  removedColor = '#e4e4e4';

  title = '';
  numRows: any = null;
  numCols: any = null;
  seatSettings: Seat[][] = [];
  seatIdDisabledMap = new Map<number, boolean>();
  id = -1;
  isFlexibleMode = false;
  flexibleCanvas = [];
  rules = {
    titleRequired: (value: any) => !!value || this.$t('seatLayoutTitleRequiredMsg'),
    rowsRequired: (value: any) => !!value || this.$t('numRowsRequiredMsg'),
    colsRequired: (value: any) => !!value || this.$t('numColsRequiredMsg')
  };

  @seatingcharts.Action
  addRoomLayout!: (params?: any) => Promise<any>;

  get seatSettingsEmpty() {
    return this.seatSettings.length === 0;
  }

  get scrollHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md':
      case 'sm': return 'calc(100vh - 350px)';
      case 'xs': return 'calc(100vh - 400px)';
    }
  }

  @Watch('numRows')
  @Watch('numCols')
  updateSeatSettings() {
    if (this.numRows !== null && this.numCols !== null && this.numRows > 0 && this.numCols > 0) {
      this.validateDimensions();
      const numStoredRows = this.seatSettings.length;

      if (numStoredRows === 0) {
        this.addRows(this.numRows, this.numCols);
      } else {
        if (this.numRows > numStoredRows) {
          this.addRows(this.numRows - numStoredRows, this.numCols);
        } else if (this.numRows < numStoredRows) {
          this.removeRows(numStoredRows - this.numRows);
        }

        const numStoredCols = this.seatSettings[0].length;

        if (this.numCols > numStoredCols) {
          this.addCols(this.numCols - numStoredCols, this.numRows);
        } else if (this.numCols < numStoredCols) {
          this.removeCols(numStoredCols - this.numCols);
        }
      }
    } else {
      this.seatSettings = [];
    }
  }

  private addRows(numRowsToAdd: number, numColsInRow: number) {
    const rowsToAdd: Seat[][] = [];

    for (let i = 0; i < numRowsToAdd; i++) {
      const row: Seat[] = [];

      for (let j = 0; j < numColsInRow; j++) {
        const id = +((this.seatSettings.length + i + 1) + '' + (j + 1));

        if (!this.seatIdDisabledMap.has(id)) {
          this.seatIdDisabledMap.set(id, false);
        }

        const mapValue = this.seatIdDisabledMap.get(id);
        const disabled = mapValue !== undefined ? mapValue : false;

        row.push({ id: id, disabled: disabled });
      }

      rowsToAdd.push(row);
    }

    for (const row of rowsToAdd) {
      this.seatSettings.push(row);
    }
  }

  private removeRows(numRowsToRemove: number) {
    for (let i = 0; i < numRowsToRemove; i++) {
      this.seatSettings.pop();
    }
  }

  private addCols(numColsToAdd: number, numRowsInCol: number) {
    const colsToAdd: Seat[][] = [];

    for (let i = 0; i < numColsToAdd; i++) {
      const col: Seat[] = [];

      for (let j = 0; j < numRowsInCol; j++) {
        const id = +((j + 1) + '' + (this.seatSettings[0].length + 1 + i));

        if (!this.seatIdDisabledMap.has(id)) {
          this.seatIdDisabledMap.set(id, false);
        }

        const mapValue = this.seatIdDisabledMap.get(id);
        const disabled = mapValue !== undefined ? mapValue : false;

        col.push({ id: id, disabled: disabled });
      }

      colsToAdd.push(col);
    }

    for (const col of colsToAdd) {
      this.seatSettings.map((elem, idx) => {
        elem.push(col[idx]);
        return elem;
      });
    }
  }

  private removeCols(numColsToRemove: number) {
    this.seatSettings = this.seatSettings.map(elem => {
      return elem.slice(0, -numColsToRemove);
    });
  }

  private validateDimensions() {
    if (this.numRows && this.numCols) {
      if (this.numRows > 20) {
        this.numRows = 20;
      }

      if (this.numCols > 20) {
        this.numCols = 20;
      }
    }
  }

  getSeatColor(seat: Seat) {
    return seat.disabled ? this.removedColor : this.activeColor;
  }

  getSeatName(i: number, j: number, seat: Seat) {
    if (seat.disabled) {
      return this.$t('disabledSeatNameLabel', { r: (i + 1), c: (j + 1) });
    } else {
      return this.$t('enabledSeatNameLabel', { r: (i + 1), c: (j + 1) });
    }
  }

  toggleSeat(seat: Seat) {
    seat.disabled = !seat.disabled;
    this.seatIdDisabledMap.set(seat.id, seat.disabled);
  }

  toggleFlexibleMode() {
    if (this.isFlexibleMode) {
      this.saveFlexibleCanvas();
    } else {
      this.numRows = CommonUtils.get(this.numRows, 1);
      this.numCols = CommonUtils.get(this.numCols, 1);
    }

    this.isFlexibleMode = !this.isFlexibleMode;
  }

  saveFlexibleCanvas() {
    let seatId = 10000;

    this.flexibleCanvas = this.$refs.flexibleLayout.strokes.map((stroke: any) => {
      if (stroke.isSeat) {
        stroke.id = seatId++;
        stroke.disabled = false;
      }
      if (stroke.type === 'dash' || stroke.type === 'eraser') {
        stroke.coordinates = stroke.coordinates.filter((pos: any) => { return (Number.isInteger(pos.x) && Number.isInteger(pos.y)) })
      }

      return stroke;
    });
  }

  addLayout() {
    const form: any = this.$refs.seatingLayoutForm;
    if (form.validate()) {
      if (this.isFlexibleMode) {
        this.saveFlexibleCanvas();
      }

      CommonUtils.showLoading();

      const layout: Layout = {
        cols: this.numCols,
        name: this.title,
        rows: this.numRows,
        settings: {
          flexible: this.isFlexibleMode,
          seatSettings: this.seatSettings.flat(),
          flexibleCanvas: this.flexibleCanvas
        }
      }

      if (this.id !== -1) {
        layout.id = this.id;
      }

      this.addRoomLayout(layout).then(() => {
        CommonUtils.hideLoading();
        this.saveLayout();
      });
    }
  }

  refreshKey() {
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  initialize() {
    if (this.originalLayout) {
      this.title = this.originalLayout.name;
      this.numRows = this.originalLayout.rows;
      this.numCols = this.originalLayout.cols;
      this.id = this.originalLayout.id === undefined ? -1 : this.originalLayout.id;
      this.flexibleCanvas = this.originalLayout.settings.flexibleCanvas;
      this.isFlexibleMode = this.originalLayout.settings.flexible;

      if (this.numRows !== null && this.numCols !== null) {
        for (let i = 0; i < this.numRows; i++) {
          this.seatSettings.push([]);

          for (let j = 0; j < this.numCols; j++) {
            const seat: Seat = this.originalLayout.settings.seatSettings[(i * this.numCols) + j];
            if (seat) {
              this.seatIdDisabledMap.set(seat.id, seat.disabled);
              this.seatSettings[i].push(seat);
            }
          }
        }
      }
    }
  }

  created() {
    this.initialize();
  }
}
